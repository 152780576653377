import { Button } from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Quiz111, Quiz112, Quiz121 } from "./Quiz11";
import { Quiz131, Quiz132, Quiz133, Quiz134 } from "./Quiz13";
import { Quiz141, Quiz142 } from "./Quiz14";
import { Quiz161, Quiz162, Quiz163, Quiz164 } from "./Quiz16";
import { getCookie } from "../../support/Cookie";

export default function Quiz1() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움받아 해결하기</h1>
      <Routes>
        <Route path="*" element={<Quiz110 />} />
        <Route path="1" element={<Quiz111 />} />
        <Route path="2" element={<Quiz112 />} />
        <Route path="3" element={<Quiz121 />} />
        <Route path="5" element={<Quiz131 />} />
        <Route path="6" element={<Quiz132 />} />
        <Route path="7" element={<Quiz133 />} />
        <Route path="8" element={<Quiz134 />} />
        <Route path="9" element={<Quiz141 />} />
        <Route path="10" element={<Quiz142 />} />
        <Route path="15" element={<Quiz161 />} />
        <Route path="16" element={<Quiz162 />} />
        <Route path="17" element={<Quiz163 />} />
        <Route path="18" element={<Quiz164 />} />
      </Routes>
    </>
  );
}

function Quiz110() {
  return (
    <>
      <h2>
        이 활동은 인지능력 향상을 위한 종합적인 문제로 구성되어있습니다. <br />
        <br />
        다른 사람의 도움을 받아도 좋습니다. 함께 활동해보세요.
      </h2>
      <Button component={Link} variant="contained" size="large" to="1">
        <h1>시작하기</h1>
      </Button>
    </>
  );
}
