/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz161() {
  return (
    <>
      <h2>아래 사람의 직업은 무엇이며 무엇을 하는 사람입니까? </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./61/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./61/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/16"
      >
        다음
      </Button>
    </>
  );
}

function Quiz162() {
  return (
    <>
      <h2>아래 사람의 직업은 무엇이며 무엇을 하는 사람입니까? </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./62/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./62/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/17"
      >
        다음
      </Button>
    </>
  );
}

function Quiz163() {
  return (
    <>
      <h2>아래 사람의 직업은 무엇이며 무엇을 하는 사람입니까? </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./63/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./63/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/18"
      >
        다음
      </Button>
    </>
  );
}

function Quiz164() {
  return (
    <>
      <h2>아래 사람의 직업은 무엇이며 무엇을 하는 사람입니까?</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./64/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz161, Quiz162, Quiz163, Quiz164 };
