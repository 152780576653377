import { createTheme } from "@mui/material";

const MUITheme = createTheme({
  palette: {
    primary: {
      main: "#3AB0FF",
      light: "#FFF9CA",
      dark: "#355764",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label": {
            fontSize: "1.5rem",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" && {
            borderRadius: 24,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#232323",
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
            },
            "&:focus": {
              boxShadow: "none",
            },
          }),
        }),
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export default MUITheme;
