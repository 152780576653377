import { atom } from "recoil";

const countQuizState = atom({
  key: "counter",
  default: 0,
});

const countQuizWin = atom({
  key: "win",
  default: 0,
});

const countQuizLose = atom({
  key: "lose",
  default: 0,
});

const authToken = atom({
  key: "token",
  default: "",
});

const LoginAuth = atom({
  key: "login",
  default: false,
});

const AdminAuth = atom({
  key: "admin",
  default: false,
});

const IDInfo = atom({
  key: "username",
  default: "unknown",
});

export {
  countQuizState,
  countQuizWin,
  countQuizLose,
  authToken,
  LoginAuth,
  AdminAuth,
  IDInfo,
};
