import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { Container, TextField, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCookie } from "./Cookie";

export default function ChangePassword() {
  const [passWord, editPassWord] = useState("");
  const [valPw, setValPw] = useState("");
  const [userName, setUsername] = useState("");

  const navigate = useNavigate();
  const TOKEN = getCookie("token");

  useEffect(() => {
    if (!(Number(getCookie("role")) > 0)) {
      navigate("/quiz");
    } else {
      setUsername(getCookie("username"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const URL = process.env.REACT_APP_API_HOST;

  const changePw = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
    passWord.length > 0 && passWord === valPw
      ? await axios
          .post(
            `${URL}/api/member/${userName}/password?${qs.stringify({
              password: passWord,
            })}`,
          )
          .then(function (response) {
            alert(response.data);
            navigate("/login");
          })
          .catch(function (error) {
            console.log(error);
          })
      : alert("비밀번호가 다릅니다");
  };

  return (
    <Container maxWidth="sm">
      <h1>비밀번호 변경</h1>
      <p>비밀번호는 신중히 변경해주세요</p>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="비밀번호를 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => editPassWord(e.target.value)}
        />
        <TextField
          fullWidth
          label="확인 비밀번호를 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => setValPw(e.target.value)}
        />
        <Button variant="contained" fullWidth size="large" onClick={changePw}>
          비밀번호 변경
        </Button>
      </Stack>
    </Container>
  );
}
