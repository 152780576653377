import { createTheme } from "@mui/material/styles";

export const theme = createTheme();
// MuiTextField의 fontSize 반응형 쿼리로 변경
theme.components.MuiTextField = {
  styleOverrides: {
    root: {
      fontSize: "1rem",
      "@media (min-width:600px)": {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.6rem",
      },
    },
  },
};
// variant ="body1" setting
theme.typography.body1 = {
  fontSize: "0.8rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.4rem",
  },
};

theme.components.MuiButton = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === "contained" && {
        borderRadius: 24,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#232323",
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      }),
    }),
  },
};
