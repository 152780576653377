import * as React from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from "@mui/material";
function Quiz111() {
  return (
    <>
      <h2>나와 가족 관계에 대하여</h2>
      <h3>
        가족은 모두 몇명입니까? <TextField />
      </h3>
      <h3>
        결혼은 언제 하셨습니까? <TextField />
      </h3>
      <h3>
        자녀는 모두 몇 명입니까? <TextField />
      </h3>
      <h3>
        제일 좋아하는 가족은 누구입니까? <TextField />
      </h3>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz112() {
  return (
    <>
      <h2>자녀, 배우자, 형제 등 가족에 대하여</h2>
      <h3>
        <TextField />의 이름이 무엇입니까?
      </h3>
      <h3>
        <TextField />의 나이는 몇살입니까?
      </h3>
      <h3>
        <TextField />의 집 주소는 어디입니까?
      </h3>
      <h3>
        <TextField />의 직업은 무엇입니까
      </h3>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/5"
      >
        다음
      </Button>
    </>
  );
}

function Quiz121() {
  return (
    <>
      <h2>날짜와 시간 맞추기</h2>
      <h3>
        보통 아침 몇시에 일어납니까? <TextField />시
      </h3>
      <h3>
        지금은 아침, 점심, 저녁 중 언제입니까? <TextField />
      </h3>
      <h3>
        어제는 몇 월, 며칠, 무슨 요일이었습니까? <TextField />
      </h3>
      <h3>
        내일은 몇 월, 며칠, 무슨 요일입니까? <TextField />
      </h3>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz122() {
  return (
    <>
      <h2>1년이란 시간이 어떻게 지나가는가</h2>
      <h3>
        일주일은 며칠입니까? <TextField />시
      </h3>
      <h3>
        한 달은 며칠입니까? <TextField />
      </h3>
      <h3>
        1년은 며칠입니까? <TextField />
      </h3>
      <h3>
        1년은 몇 달입니까?
        <TextField />
      </h3>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/5"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz111, Quiz112, Quiz121, Quiz122 };
