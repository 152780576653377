/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Star, Circle, Square, ArrowDropUp } from "@mui/icons-material";
import { saveResultInspect } from "../Quiz";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import "./Quiz3.sass";
import SpeakButton from "../quiz_support/TextToSpeech";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";

export default function Quiz1() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 3;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate("/quiz/" + QuizNum + "/" + ++pageNum);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  const OXButton = styled(Button)({
    fontSize: "55px",
    height: "70px",
    borderRadius: "20px",
    margin: "3px",
    lineHeight: "65px",
    "&.MuiButton-outlined": { border: "3px solid red" },
  });

  const Quiz300 = () => {
    useEffect(() => {
      return () => {
        resetCounter();
        resetWin();
        resetLose();
      };
    }, []);
    return (
      <>
        <h1>집중력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  };

  const Quiz301 = () => {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn1"] ? "outlined" : "text"}
              onClick={handleBtn("btn1")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["16"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("16")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn2"] ? "outlined" : "text"}
              onClick={handleBtn("btn2")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn3"] ? "outlined" : "text"}
              onClick={handleBtn("btn3")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn4"] ? "outlined" : "text"}
              onClick={handleBtn("btn4")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn5"] ? "outlined" : "text"}
              onClick={handleBtn("btn5")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn6"] ? "outlined" : "text"}
              onClick={handleBtn("btn6")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn7"] ? "outlined" : "text"}
              onClick={handleBtn("btn7")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn8"] ? "outlined" : "text"}
              onClick={handleBtn("btn8")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn9"] ? "outlined" : "text"}
              onClick={handleBtn("btn9")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn14"] ? "outlined" : "text"}
              onClick={handleBtn("btn14")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["btn10"] ? "outlined" : "text"}
              onClick={handleBtn("btn10")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn11"] ? "outlined" : "text"}
              onClick={handleBtn("btn11")}
            >
              ㅊ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
            >
              ㅈ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn12"] ? "outlined" : "text"}
              onClick={handleBtn("btn12")}
            >
              ㅅ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["btn13"] ? "outlined" : "text"}
              onClick={handleBtn("btn13")}
            >
              ㅊ
            </OXButton>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isClick);
              const failedValues = Object.values(isNot);
              if (
                rightValues.length == 14 &&
                rightValues.filter(isright => isright == true).length == 14 &&
                failedValues.filter(isnot => isnot == true).length < 1
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz302 = () => {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
            >
              ㅍ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["5"] ? "outlined" : "text"}
              onClick={handleBtn("5")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["6"] ? "outlined" : "text"}
              onClick={handleBtn("6")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["7"] ? "outlined" : "text"}
              onClick={handleBtn("7")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["8"] ? "outlined" : "text"}
              onClick={handleBtn("8")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["9"] ? "outlined" : "text"}
              onClick={handleBtn("9")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["10"] ? "outlined" : "text"}
              onClick={handleBtn("10")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["11"] ? "outlined" : "text"}
              onClick={handleBtn("11")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["12"] ? "outlined" : "text"}
              onClick={handleBtn("12")}
            >
              ㅍ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["13"] ? "outlined" : "text"}
              onClick={handleBtn("13")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["14"] ? "outlined" : "text"}
              onClick={handleBtn("14")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["15"] ? "outlined" : "text"}
              onClick={handleBtn("15")}
            >
              ㅌ
            </OXButton>
          </Grid>{" "}
          <Grid item xs={2}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["16"] ? "outlined" : "text"}
              onClick={handleBtn("16")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["17"] ? "outlined" : "text"}
              onClick={handleBtn("17")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["18"] ? "outlined" : "text"}
              onClick={handleBtn("18")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["19"] ? "outlined" : "text"}
              onClick={handleBtn("19")}
            >
              ㄹ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["20"] ? "outlined" : "text"}
              onClick={handleBtn("20")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["21"] ? "outlined" : "text"}
              onClick={handleBtn("21")}
            >
              ㅍ
            </OXButton>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isClick);
              const failedValues = Object.values(isNot);
              if (
                rightValues.length == 21 &&
                rightValues.filter(isright => isright == true).length == 21 &&
                failedValues.filter(isnot => isnot == true).length < 1
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz303 = () => {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              ㅁ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
            >
              ㅁ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["5"] ? "outlined" : "text"}
              onClick={handleBtn("5")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["6"] ? "outlined" : "text"}
              onClick={handleBtn("6")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["12"] ? "outlined" : "text"}
              onClick={handleBtn("12")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["7"] ? "outlined" : "text"}
              onClick={handleBtn("7")}
            >
              ㅁ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["8"] ? "outlined" : "text"}
              onClick={handleBtn("8")}
            >
              ㅁ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["9"] ? "outlined" : "text"}
              onClick={handleBtn("9")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["16"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("16")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["17"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("17")}
            >
              ㅎ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["10"] ? "outlined" : "text"}
              onClick={handleBtn("10")}
            >
              ㅇ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["11"] ? "outlined" : "text"}
              onClick={handleBtn("11")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["18"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("18")}
            >
              ㅎ
            </OXButton>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isClick);
              const failedValues = Object.values(isNot);
              if (
                rightValues.length == 12 &&
                rightValues.filter(isright => isright == true).length == 12 &&
                failedValues.filter(isnot => isnot == true).length < 1
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz304 = () => {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              ㄴ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
            >
              ㄱ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["5"] ? "outlined" : "text"}
              onClick={handleBtn("5")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["6"] ? "outlined" : "text"}
              onClick={handleBtn("6")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["7"] ? "outlined" : "text"}
              onClick={handleBtn("7")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["8"] ? "outlined" : "text"}
              onClick={handleBtn("8")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["9"] ? "outlined" : "text"}
              onClick={handleBtn("9")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["10"] ? "outlined" : "text"}
              onClick={handleBtn("10")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["14"] ? "outlined" : "text"}
              onClick={handleBtn("14")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["15"] ? "outlined" : "text"}
              onClick={handleBtn("15")}
            >
              ㄱ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["16"] ? "outlined" : "text"}
              onClick={handleBtn("16")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["17"] ? "outlined" : "text"}
              onClick={handleBtn("17")}
            >
              ㅌ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["18"] ? "outlined" : "text"}
              onClick={handleBtn("18")}
            >
              ㄴ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["19"] ? "outlined" : "text"}
              onClick={handleBtn("19")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["20"] ? "outlined" : "text"}
              onClick={handleBtn("20")}
            >
              ㄱ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["21"] ? "outlined" : "text"}
              onClick={handleBtn("21")}
            >
              ㄷ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
            >
              ㅋ
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["22"] ? "outlined" : "text"}
              onClick={handleBtn("22")}
            >
              ㅌ
            </OXButton>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isClick);
              const failedValues = Object.values(isNot);
              if (
                rightValues.length == 19 &&
                rightValues.filter(isright => isright == true).length == 19 &&
                failedValues.filter(isnot => isnot == true).length < 1
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const QuizTextField = styled(TextField)({
    fontSize: "55px",
    "&.MuiButton-outlined": { border: "3px solid red" },
  });

  const Quiz305 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };

    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <table>
              <tr>
                <td>1</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("2")}
                  />
                </td>
                <td>3</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("4")}
                  />
                </td>
                <td>5</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("6")}
                  />
                </td>
                <td>7</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("8")}
                  />
                </td>
                <td>9</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("10")}
                  />
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td>12</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("13")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    onChange={handleTF("14")}
                  />
                </td>
                <td>15</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("16")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("17")}
                  />
                </td>
                <td>18</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("19")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("20")}
                  />
                </td>
              </tr>
              <tr>
                <td>21</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("22")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("23")}
                  />
                </td>
                <td>24</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("25")}
                  />
                </td>
              </tr>
              <tr>
                <td>26</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("27")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("28")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("29")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("30")}
                  />
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isCorrect);
              if (rightValues.filter(isright => isright == true).length == 18) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz306 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };

    const pageCount = 6;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <table>
              <tr>
                <td>ㄱ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㄴ")}
                  />
                </td>
                <td>ㄷ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㄹ")}
                  />
                </td>
                <td>ㅁ</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅂ")}
                  />
                </td>
                <td>ㅅ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅇ")}
                  />
                </td>
                <td>ㅈ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅊ")}
                  />
                </td>
              </tr>
              <tr>
                <td>ㅋ</td>
                <td>ㅌ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅍ")}
                  />
                </td>
                <td>ㅎ</td>
                <td>ㅏ</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅑ")}
                  />
                </td>
                <td>ㅓ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅕ")}
                  />
                </td>
                <td>ㅗ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅛ")}
                  />
                </td>
              </tr>
              <tr>
                <td>ㅜ</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅠ")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("ㅡ")}
                  />
                </td>
                <td>ㅣ</td>
                <td>가</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("나")}
                  />
                </td>
                <td>다</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("라")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("마")}
                  />
                </td>
                <td>바</td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isCorrect);
              if (rightValues.filter(isright => isright == true).length == 14) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz307 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };

    const pageCount = 7;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <table>
              <tr>
                <td>100</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("99")}
                  />
                </td>
                <td>98</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("97")}
                  />
                </td>
                <td>96</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("95")}
                  />
                </td>
                <td>94</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("93")}
                  />
                </td>
                <td>92</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("91")}
                  />
                </td>
              </tr>
              <tr>
                <td>90</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("89")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("88")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("87")}
                  />
                </td>
                <td>86</td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("85")}
                  />
                </td>
                <td>84</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("83")}
                  />
                </td>
                <td>82</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("81")}
                  />
                </td>
              </tr>
              <tr>
                <td>80</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("79")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("78")}
                  />
                </td>
                <td>77</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("76")}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("75")}
                  />
                </td>
                <td>74</td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("73")}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{ style: { fontSize: 30 } }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    onChange={handleTF("72")}
                  />
                </td>
                <td>71</td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const rightValues = Object.values(isCorrect);
              if (rightValues.filter(isright => isright == true).length == 17) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz308 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      setIsCorrect(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };

    const pageCount = 8;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              src={require("./quiz3/1.png")}
              style={{ border: "1px solid black" }}
            />
          </Grid>
          <Grid item xs={12}>
            <table>
              <tr>
                <td onClick={handleTF("1")}>
                  {isCorrect["1"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("2")}>
                  {isCorrect["2"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("3")}>
                  {isCorrect["3"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("4")}>
                  {isCorrect["4"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
              <tr>
                <td onClick={handleTF("5")}>
                  {isCorrect["5"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("6")}>
                  {isCorrect["6"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("7")}>
                  {isCorrect["7"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("8")}>
                  {isCorrect["8"] ? <Star sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const values = Object.values(isCorrect);
              if (
                values.filter(isright => isright == true).length == 4 &&
                isCorrect["2"] == true &&
                isCorrect["4"] == true &&
                isCorrect["5"] == true &&
                isCorrect["6"] == true
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz309 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      setIsCorrect(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };

    const pageCount = 9;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              src={require("./quiz3/2.png")}
              style={{ border: "1px solid black" }}
            />
          </Grid>
          <Grid item xs={12}>
            <table>
              <tr>
                <td onClick={handleTF("1")}>
                  {isCorrect["1"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("2")}>
                  {isCorrect["2"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("3")}>
                  {isCorrect["3"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("4")}>
                  {isCorrect["4"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
              <tr>
                <td onClick={handleTF("5")}>
                  {isCorrect["5"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("6")}>
                  {isCorrect["6"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("7")}>
                  {isCorrect["7"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("8")}>
                  {isCorrect["8"] ? <Circle sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const values = Object.values(isCorrect);
              if (
                values.filter(isright => isright == true).length == 4 &&
                isCorrect["2"] == true &&
                isCorrect["5"] == true &&
                isCorrect["7"] == true &&
                isCorrect["8"] == true
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz310 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      setIsCorrect(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };

    const pageCount = 10;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              src={require("./quiz3/3.png")}
              style={{ border: "1px solid black" }}
            />
          </Grid>
          <Grid item xs={12}>
            <table>
              <tr>
                <td onClick={handleTF("1")}>
                  {isCorrect["1"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("2")}>
                  {isCorrect["2"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("3")}>
                  {isCorrect["3"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("4")}>
                  {isCorrect["4"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
              <tr>
                <td onClick={handleTF("5")}>
                  {isCorrect["5"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("6")}>
                  {isCorrect["6"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("7")}>
                  {isCorrect["7"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
                <td onClick={handleTF("8")}>
                  {isCorrect["8"] ? <Square sx={{ fontSize: "66px" }} /> : ""}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const values = Object.values(isCorrect);
              if (
                values.filter(isright => isright == true).length == 4 &&
                isCorrect["1"] == true &&
                isCorrect["3"] == true &&
                isCorrect["5"] == true &&
                isCorrect["6"] == true
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Quiz311 = () => {
    const [isCorrect, setIsCorrect] = useState({});

    const handleTF = Id => e => {
      setIsCorrect(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };

    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              src={require("./quiz3/4.png")}
              style={{ border: "1px solid black" }}
            />
          </Grid>
          <Grid item xs={12}>
            <table>
              <tr>
                <td onClick={handleTF("1")}>
                  {isCorrect["1"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("2")}>
                  {isCorrect["2"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("3")}>
                  {isCorrect["3"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("4")}>
                  {isCorrect["4"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td onClick={handleTF("5")}>
                  {isCorrect["5"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("6")}>
                  {isCorrect["6"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("7")}>
                  {isCorrect["7"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
                <td onClick={handleTF("8")}>
                  {isCorrect["8"] ? (
                    <Typography sx={{ fontSize: "66px" }}> ▲ </Typography>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const values = Object.values(isCorrect);
              if (
                values.filter(isright => isright == true).length == 4 &&
                isCorrect["3"] == true &&
                isCorrect["4"] == true &&
                isCorrect["5"] == true &&
                isCorrect["6"] == true
              ) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  const Score = () => {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>집중력 증진 활동</h1>
        <h2>
          <b>점수</b>
        </h2>
        <h2>현재 푼 문제 : {quizCount}</h2>
        <h2>맞춘 문제 : {winCount}</h2>
        <h2>틀린 문제 : {loseCount}</h2>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "집중력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/" + String(QuizNum + 1) + "/");
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path={"*"} element={<Quiz300 />} />
        <Route path={"1"} element={<Quiz301 />} />
        <Route path={"2"} element={<Quiz302 />} />
        <Route path={"3"} element={<Quiz303 />} />
        <Route path={"4"} element={<Quiz304 />} />
        <Route path={"5"} element={<Quiz305 />} />
        <Route path={"6"} element={<Quiz306 />} />
        <Route path={"7"} element={<Quiz307 />} />
        <Route path={"8"} element={<Quiz308 />} />
        <Route path={"9"} element={<Quiz309 />} />
        <Route path={"10"} element={<Quiz310 />} />
        <Route path={"11"} element={<Quiz311 />} />
        <Route path={"12"} element={<Score />} />
      </Routes>
    </>
  );
}
