/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz131() {
  return (
    <>
      <h2>다음 그림들 중 여름에 해당하는 그림을 찾아 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./31/311.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./31/312.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./31/313.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./31/314.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/6"
      >
        다음
      </Button>
    </>
  );
}

function Quiz132() {
  return (
    <>
      <h2>다음 그림들 중 겨울에 해당하는 그림을 찾아 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./32/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./32/2.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./32/3.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/7"
      >
        다음
      </Button>
    </>
  );
}

function Quiz133() {
  return (
    <>
      <h2>다음 그림을 ‘봄-여름-가을-겨울’ 순서대로 찾아 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./33/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./33/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./33/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./33/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/8"
      >
        다음
      </Button>
    </>
  );
}

function Quiz134() {
  return (
    <>
      <h2>다음 그림을 ‘봄-여름-가을-겨울’ 순서대로 찾아 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./34/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./34/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./34/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./34/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/9"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz131, Quiz132, Quiz133, Quiz134 };
