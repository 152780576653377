/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState, useRef } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Typography,
  createTheme,
} from "@mui/material";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import { saveResultInspect } from "../Quiz";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import {
  quizInfoText,
  quizPageText,
  quizText10,
  quizTitleText,
} from "./QuizText";
import SpeakButton from "../quiz_support/TextToSpeech";
import { border, flexbox, fontSize, ThemeProvider } from "@mui/system";
import "./ImageBorder.sass";
import { theme } from "../quiz_support/QuizVarient";

export default function Quiz10() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 10;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  // Quiz 하단 부 건너뛰기+정답확인 -> Stack 컴포넌트로 감싼 공통 컴포넌트임
  function QuizBottomComponent({ pageCount, checkFunc }) {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc()) {
                // 정답 로직
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  function Quiz1000() {
    useEffect(
      () => () => {
        resetCounter();
        resetWin();
        resetLose();
      },
      [],
    );
    return (
      <>
        <h1>실행능력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  function Quiz1001() {
    const [value, setValue] = useState("");

    const quizAnswerCheck = () => {
      if (value == "4") {
        return true;
      }
      return false;
    };

    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/1.png")} />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1002() {
    const [value, setValue] = useState("");
    const pageCount = 2;
    const quizAnswerCheck = () => {
      if (value == "6") {
        return true;
      }
      return false;
    };

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/2.png")} />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
              />
            </FormControl>{" "}
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1003() {
    const [value, setValue] = useState("");
    const quizAnswerCheck = () => {
      if (value == "6") {
        return true;
      }
      return false;
    };

    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/3.png")} />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
                inputProps={{
                  style: { fontSize: 25 },
                }}
              />
            </FormControl>{" "}
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1004() {
    const [value, setValue] = useState("");
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/4.png")} />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
              />
            </FormControl>{" "}
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "9") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz1005() {
    const pageCount = 5;
    return (
      <>
        <h1 style={{ letterSpacing: "-3px" }}>
          프로그램 : {quizTitleText[QuizNum][1]}
        </h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img
                width="100%"
                // style={{ border: "1px solid black" }}
                src={require("./quiz10/5.png")}
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/6.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/7.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz1006() {
    const pageCount = 6;
    return (
      <>
        <h1 style={{ letterSpacing: "-3px" }}>
          프로그램 : {quizTitleText[QuizNum][1]}
        </h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/8.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/9.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/10.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz1007() {
    const pageCount = 7;
    return (
      <>
        <h1 style={{ letterSpacing: "-3px" }}>
          프로그램 : {quizTitleText[QuizNum][1]}
        </h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/11.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/12.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/13.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz1008() {
    const pageCount = 8;
    return (
      <>
        <h1 style={{ letterSpacing: "-3px" }}>
          프로그램 : {quizTitleText[QuizNum][1]}
        </h1>
        <h2>{quizPageText[QuizNum][5]}</h2>
        <SpeakButton text={quizPageText[QuizNum][5]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/14.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/15.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz10/16.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz1009() {
    const value1 = [];
    const value2 = [];
    const quizAnswer = [
      [3, 2, 1],
      [3, 2, 1],
    ];
    const pageCount = 9;

    const onChangeValue1 = (id, value) => {
      value1[id] = value;
    };

    const onChangeValue2 = (id, value) => {
      value2[id] = value;
    };

    const quizAnswerCheck = () => {
      for (let i = 0; i < quizAnswer[0].length; i++) {
        const isCheck1 = value1[i] == quizAnswer[0][i] ? true : false;
        const isCheck2 = value2[i] == quizAnswer[1][i] ? true : false;
        if (isCheck1 == false || isCheck2 == false) {
          return false;
        }
      }
      return true;
    };

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][6]}</h2>
        <SpeakButton text={quizPageText[QuizNum][6]} />
        <h2>동작1 ) 물마시기</h2>
        <SpeakButton text={quizPageText[QuizNum][7]} />
        <img
          className="quizImageBorder"
          width="100%"
          src={require("./quiz10/17.png")}
        />
        <Stack
          direction="row"
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <Typography
            sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
            variant="body1"
          >
            순서 :
          </Typography>
          <TextField
            label="첫번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(0, e.target.value);
            }}
          />
          <TextField
            label="두번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(1, e.target.value);
            }}
          />
          <TextField
            label="세번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(2, e.target.value);
            }}
          />
        </Stack>
        <h2>동작2 ) 전화걸기</h2>
        <SpeakButton text={quizPageText[QuizNum][8]} />
        <img
          className="quizImageBorder"
          width="100%"
          src={require("./quiz10/18.png")}
        />
        <Stack
          direction="row"
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <Typography
            sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
            variant="body1"
          >
            순서 :
          </Typography>
          <TextField
            label="첫번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(0, e.target.value);
            }}
          />
          <TextField
            label="두번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(1, e.target.value);
            }}
          />
          <TextField
            label="세번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(2, e.target.value);
            }}
          />
        </Stack>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1010() {
    const value1 = [];
    const value2 = [];
    const quizAnswer = [
      [2, 4, 1, 3],
      [4, 1, 3, 2],
    ];
    const pageCount = 10;

    const onChangeValue1 = (id, value) => {
      value1[id] = value;
    };

    const onChangeValue2 = (id, value) => {
      value2[id] = value;
    };

    const quizAnswerCheck = () => {
      for (let i = 0; i < quizAnswer[0].length; i++) {
        const isTrue1 = value1[i] == quizAnswer[0][i] ? true : false;
        const isTrue2 = value2[i] == quizAnswer[1][i] ? true : false;
        if (isTrue1 == false || isTrue2 == false) {
          return false;
        }
      }
      return true;
    };

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][6]}</h2>
        <SpeakButton text={quizPageText[QuizNum][6]} />
        <h2>동작1 ) 등산하기</h2>
        <SpeakButton text={quizPageText[QuizNum][9]} />
        <img
          className="quizImageBorder"
          width="100%"
          src={require("./quiz10/26.png")}
        />
        <Stack
          direction="row"
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            alignItems: "center",
            fontSize: "1.4rem",
          }}
        >
          <Typography
            sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
            variant="body1"
          >
            순서 :
          </Typography>
          <TextField
            label="첫번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(0, e.target.value);
            }}
          />
          <TextField
            label="두번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(1, e.target.value);
            }}
          />
          <TextField
            label="세번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(2, e.target.value);
            }}
          />
          <TextField
            label="네번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue1(3, e.target.value);
            }}
          />
        </Stack>
        <h2>동작2 ) 라면 끓이기</h2>
        <SpeakButton text={quizPageText[QuizNum][10]} />
        <img
          className="quizImageBorder"
          width="100%"
          src={require("./quiz10/27.png")}
        />
        <Stack
          direction="row"
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            alignItems: "center",
            fontSize: "1.4rem",
          }}
        >
          <Typography
            sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
            variant="body1"
          >
            순서 :
          </Typography>
          <TextField
            label="첫번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(0, e.target.value);
            }}
          />
          <TextField
            label="두번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(1, e.target.value);
            }}
          />
          <TextField
            label="세번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(2, e.target.value);
            }}
          />
          <TextField
            label="네번째"
            sx={{ ml: 1, mr: 1, width: "8ch" }}
            type="number"
            onChange={e => {
              onChangeValue2(3, e.target.value);
            }}
          />
        </Stack>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1011() {
    const [value, setValue] = useState("");
    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][11]}</h2>
        <SpeakButton text={quizPageText[QuizNum][11]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/19.png")} />
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "3") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz1012() {
    const [value, setValue] = useState("");
    const pageCount = 12;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][12]}</h2>
        <SpeakButton text={quizPageText[QuizNum][12]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/20.png")} />
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "3") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz1013() {
    const value1 = [];
    const quizAnswer = [2, 3, 4, 1];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    const quizAnswerCheck = () => {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    };

    const pageCount = 13;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][13]}</h2>
        <SpeakButton text={quizPageText[QuizNum][13]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/21.png")} />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                순서 :
              </Typography>
              <TextField
                label="첫번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(0, e.target.value);
                }}
              />
              <TextField
                label="두번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(1, e.target.value);
                }}
              />
              <TextField
                label="세번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(2, e.target.value);
                }}
              />
              <TextField
                label="네번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(3, e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1014() {
    const value1 = [];
    const quizAnswer = [1, 3, 4, 2];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    const quizAnswerCheck = () => {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    };

    const pageCount = 14;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][14]}</h2>
        <SpeakButton text={quizPageText[QuizNum][14]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/22.png")} />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                순서 :
              </Typography>
              <TextField
                label="첫번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(0, e.target.value);
                }}
              />
              <TextField
                label="두번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(1, e.target.value);
                }}
              />
              <TextField
                label="세번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(2, e.target.value);
                }}
              />
              <TextField
                label="네번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(3, e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1015() {
    const value1 = [];
    const quizAnswer = [1, 3, 2];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    function quizAnswerCheck() {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    }

    const pageCount = 15;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][4]}</h1>
        <h2>{quizPageText[QuizNum][15]}</h2>
        <SpeakButton text={quizPageText[QuizNum][15]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/23.png")} />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                순서 :
              </Typography>
              <TextField
                label="첫번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(0, e.target.value);
                }}
              />
              <TextField
                label="두번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(1, e.target.value);
                }}
              />
              <TextField
                label="세번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(2, e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1016() {
    const value1 = [];
    const quizAnswer = [3, 1, 2];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    function quizAnswerCheck() {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    }

    const pageCount = 16;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][4]}</h1>
        <h2>{quizPageText[QuizNum][15]}</h2>
        <SpeakButton text={quizPageText[QuizNum][15]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/24.png")} />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                순서 :
              </Typography>
              <TextField
                label="첫번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(0, e.target.value);
                }}
              />
              <TextField
                label="두번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(1, e.target.value);
                }}
              />
              <TextField
                label="세번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(2, e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1017() {
    const value1 = [];
    const quizAnswer = [1, 2, 3];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    function quizAnswerCheck() {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    }
    const pageCount = 17;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][4]}</h1>
        <h2>{quizPageText[QuizNum][15]}</h2>
        <SpeakButton text={quizPageText[QuizNum][15]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz10/25.png")} />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                순서 :
              </Typography>
              <TextField
                label="첫번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(0, e.target.value);
                }}
              />
              <TextField
                label="두번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(1, e.target.value);
                }}
              />
              <TextField
                label="세번째"
                sx={{ ml: 1, mr: 1, width: "8ch" }}
                type="number"
                onChange={e => {
                  onChangeValue(2, e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1018() {
    const quizAnswer = [
      "밀다",
      "내려가다",
      "깨끗하다",
      "시끄럽다",
      "입다",
      "낮다",
      "얇다",
      "여자",
    ];
    const value1 = [];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    function quizAnswerCheck() {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        console.log(value1);
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    }
    const pageCount = 18;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][5]}</h1>
        <h2>{quizPageText[QuizNum][16]}</h2>
        <SpeakButton text={quizPageText[QuizNum][16]} />
        <br></br>
        <SpeakButton text={quizText10[0]} />
        <ThemeProvider theme={theme}>
          <Stack spacing={2} sx={{ width: "auto" }}>
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(0, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    당기다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(1, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    올라가다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(2, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    더럽다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(3, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    조용하다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(4, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    벗다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(5, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    높다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(6, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    두껍다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(7, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    남자 :
                  </Typography>
                </InputAdornment>
              }
            />
          </Stack>
        </ThemeProvider>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Quiz1019() {
    const quizAnswer = [
      "배부르다",
      "춥다",
      "느리다",
      "짧다",
      "남쪽",
      "오다",
      "아래쪽",
      "비우다",
    ];
    const value1 = [];
    const onChangeValue = (id: number, value) => {
      value1[id] = value;
    };
    function quizAnswerCheck() {
      for (let i = 0; i < quizAnswer.length; i++) {
        const isTrue = value1[i] == quizAnswer[i] ? true : false;
        if (isTrue == false) {
          return false;
        }
      }
      return true;
    }
    const pageCount = 19;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][5]}</h1>
        <h2>{quizPageText[QuizNum][16]}</h2>
        <SpeakButton text={quizPageText[QuizNum][16]} />
        <br />
        <SpeakButton text={quizText10[1]} />
        <ThemeProvider theme={theme}>
          <Stack spacing={2} sx={{ width: "auto" }}>
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(0, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    배고프다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(1, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    덥다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(2, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    빠르다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(3, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    길다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(4, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    북쪽 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(5, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    가다 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(6, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    위쪽 :
                  </Typography>
                </InputAdornment>
              }
            />
            <OutlinedInput
              type="text"
              onChange={e => {
                onChangeValue(7, e.target.value);
              }}
              placeholder="이곳에 반대말을 적어주세요"
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    채우다 :
                  </Typography>
                </InputAdornment>
              }
            />
          </Stack>
        </ThemeProvider>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={quizAnswerCheck}
        />
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>실행능력 증진 활동</h1>
        <h2>점수</h2>
        <h3>현재 푼 문제 : {quizCount}</h3>
        <h3>맞춘 문제 : {winCount}</h3>
        <h3>틀린 문제 : {loseCount}</h3>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "실행능력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<Quiz1000 />} />
          <Route path="1" element={<Quiz1001 />} />
          <Route path="2" element={<Quiz1002 />} />
          <Route path="3" element={<Quiz1003 />} />
          <Route path="4" element={<Quiz1004 />} />
          <Route path="5" element={<Quiz1005 />} />
          <Route path="6" element={<Quiz1006 />} />
          <Route path="7" element={<Quiz1007 />} />
          <Route path="8" element={<Quiz1008 />} />
          <Route path="9" element={<Quiz1009 />} />
          <Route path="10" element={<Quiz1010 />} />
          <Route path="11" element={<Quiz1011 />} />
          <Route path="12" element={<Quiz1012 />} />
          <Route path="13" element={<Quiz1013 />} />
          <Route path="14" element={<Quiz1014 />} />
          <Route path="15" element={<Quiz1015 />} />
          <Route path="16" element={<Quiz1016 />} />
          <Route path="17" element={<Quiz1017 />} />
          <Route path="18" element={<Quiz1018 />} />
          <Route path="19" element={<Quiz1019 />} />
          <Route path="20" element={<Score />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
