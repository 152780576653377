// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container } from "@mui/material";
import QuizList from "./QuizList";
import Quiz1 from "./quizself/Quiz1";
import Quiz3 from "./quizself/Quiz3";
import Quiz4 from "./quizself/Quiz4";
import Quiz5 from "./quizself/Quiz5";
import Quiz6 from "./quizself/Quiz6";
import Quiz7 from "./quizself/Quiz7";
import Quiz8 from "./quizself/Quiz8";
import Quiz9 from "./quizself/Quiz9";
import Quiz10 from "./quizself/Quiz10";
import { getCookie } from "./support/Cookie";

export default function Quiz() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!(0 < Number(getCookie("role")))) navigate("/login");
  });

  return (
    <Container maxWidth="sm">
      <Routes>
        <Route path="*" element={<QuizList />} />
        <Route path={"1/*"} element={<Quiz1 />} />
        <Route path={"3/*"} element={<Quiz3 />} />
        <Route path={"4/*"} element={<Quiz4 />} />
        <Route path={"5/*"} element={<Quiz5 />} />
        <Route path={"6/*"} element={<Quiz6 />} />
        <Route path={"7/*"} element={<Quiz7 />} />
        <Route path={"8/*"} element={<Quiz8 />} />
        <Route path={"9/*"} element={<Quiz9 />} />
        <Route path={"10/*"} element={<Quiz10 />} />
      </Routes>
    </Container>
  );
}

export const saveResultInspect = (
  title: string,
  count: number,
  win: number,
  lose: number,
) => {
  const username = getCookie("username");
  const URL = process.env.REACT_APP_API_HOST;
  const TOKEN = getCookie("token");
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
  axios
    .post(`${URL}/api/${username}/inspect/result/new`, {
      inspectionTitle: title,
      total: count,
      success: win,
      fail: lose,
    })
    .then(response => {
      alert(response.data);
    })
    .catch(error => {
      console.log(error);
    });
};
