import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz261, Quiz262, Quiz263, Quiz264 } from "./Quiz526";
import { Quiz271, Quiz272, Quiz273, Quiz274 } from "./Quiz527";
import { getCookie } from "../../support/Cookie";

export default function Quiz5() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz261 />} />
        <Route path="2" element={<Quiz262 />} />
        <Route path="3" element={<Quiz263 />} />
        <Route path="4" element={<Quiz264 />} />
        <Route path="5" element={<Quiz271 />} />
        <Route path="6" element={<Quiz272 />} />
        <Route path="7" element={<Quiz273 />} />
        <Route path="8" element={<Quiz274 />} />
      </Routes>
    </>
  );
}
