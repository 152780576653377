import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz331, Quiz332 } from "./Quiz631";
import { getCookie } from "../../support/Cookie";

export default function Quiz6() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz331 />} />
        <Route path="2" element={<Quiz332 />} />
      </Routes>
    </>
  );
}
