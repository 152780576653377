import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "700px",
};

function Quiz471() {
  return (
    <>
      <h2>다음의 주어진 단어들로 일상샐활에서 일어나는 이야기를 말해보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./47/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz8/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz472() {
  return (
    <>
      <h2>다음의 주어진 단어들로 일상샐활에서 일어나는 이야기를 말해보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./47/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz8/3"
      >
        다음
      </Button>
    </>
  );
}

function Quiz491() {
  return (
    <>
      <h2>아래 시계에 7시 45분을 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./49/1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz8/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz492() {
  return (
    <>
      <h2>아래 시계에 9시 30분을 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./49/1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz9/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz471, Quiz472, Quiz491, Quiz492 };
