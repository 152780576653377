import { Grid } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getCookie } from "../support/Cookie";
import AdminSideBar from "./AdminSideBar";
import AdminMain from "./sub/AdminMain";
import AdminUser from "./sub/AdminUser";
import AdminAdd from "./sub/AdminAdd";

export default function Admin() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>관리자 페이지</h1>
      <hr />
      <br />
      <Grid container spacing={0.5}>
        <Grid item xs={4}>
          <Grid container justifyContent="center" spacing={2}>
            <AdminSideBar />
          </Grid>
        </Grid>
        <Routes>
          <Route path="*" element={<AdminMain />} />
          <Route path="user" element={<AdminUser />} />
          <Route path="addadmin" element={<AdminAdd />} />
        </Routes>
      </Grid>
    </>
  );
}
