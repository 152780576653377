import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz111() {
  return (
    <>
      <h2>다음 단어를 시작으로 끝말 이어가기를 해보세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./110/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/19"
      >
        다음
      </Button>
    </>
  );
}

function Quiz112() {
  return (
    <>
      <h2>특정 단어로 끝나는 단어를 나열하기</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./110/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/20"
      >
        다음
      </Button>
    </>
  );
}

function Quiz121() {
  return (
    <>
      <h2>다음 그림을 보고 어떤 그림인지 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./120/1.png")} />
        </Grid>
      </Grid>
      <h3>
        ⇛ 그림에 대한 설명이 어려울 경우, 질문의 범위를 좁혀준다. “남자가 어떤
        운동을 하고 있는지 설명해주세요”
      </h3>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/21"
      >
        다음
      </Button>
    </>
  );
}

function Quiz122() {
  return (
    <>
      <h2>다음 그림을 보고 어떤 그림인지 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./120/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/22"
      >
        다음
      </Button>
    </>
  );
}

function Quiz123() {
  return (
    <>
      <h2>다음 그림을 보고 어떤 그림인지 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./120/3.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./120/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/5"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz111, Quiz112, Quiz121, Quiz122, Quiz123 };
