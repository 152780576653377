import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

function Quiz211() {
  return (
    <>
      <h2>동요를 같이 부른 후 괄호안의 가사를 혼자 불러보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./210/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz4/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz212() {
  return (
    <>
      <h2>동요를 같이 부른 후 괄호안의 가사를 혼자 불러보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./210/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz4/3"
      >
        다음
      </Button>
    </>
  );
}

function Quiz213() {
  return (
    <>
      <h2>동요를 같이 부른 후 2절부터 가사를 혼자 불러보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./210/3.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz4/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz214() {
  return (
    <>
      <h2>동요를 같이 부른 후 2절부터 가사를 혼자 불러보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./210/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz4/5"
      >
        다음
      </Button>
    </>
  );
}

function Quiz215() {
  return (
    <>
      <h2>먼저 다음 노래를 읽어보고 혼자 노래를 불러보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./210/5.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz211, Quiz212, Quiz213, Quiz214, Quiz215 };
