import React, { useState, useEffect } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "600px",
};

function Quiz331() {
  const [state, isState] = useState(true);
  const [color, isColor] = useState("blue");
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const timerId = setTimeout(() => {
      isState(false);
    }, 5000);
  }, []);
  return (
    <>
      <h2>
        {state
          ? "다음 그림을 외우고 5초 뒤에 똑같이 그리세요"
          : "앞서 본 그림과 똑같이 그리세요"}
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {state ? (
            ""
          ) : (
            <FormControl>
              <FormLabel>색상 선택</FormLabel>
              <RadioGroup
                row
                value={color}
                onChange={e => {
                  isColor(e.target.value);
                }}
              >
                <FormControlLabel
                  value="blue"
                  control={<Radio />}
                  label="파랑색"
                />
                <FormControlLabel
                  value="green"
                  control={<Radio />}
                  label="초록색"
                />
                <FormControlLabel
                  value="orange"
                  control={<Radio />}
                  label="주황색"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          {state ? (
            <img width="100%" src={require("./33/1.png")} />
          ) : (
            <ReactSketchCanvas
              style={styles}
              strokeWidth={4}
              strokeColor={color}
            />
          )}
        </Grid>
      </Grid>
      {state ? (
        ""
      ) : (
        <Button
          component={Link}
          variant="contained"
          size="large"
          to="/help/quiz6/2"
        >
          다음
        </Button>
      )}
    </>
  );
}

function Quiz332() {
  const [state, isState] = useState(true);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const timerId = setTimeout(() => {
      isState(false);
    }, 5000);
  }, []);
  return (
    <>
      <h2>
        {state
          ? "다음 그림을 외우고 5초 뒤에 똑같이 그리세요"
          : "앞서 본 그림과 똑같이 그리세요"}
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {state ? (
            <img width="100%" src={require("./33/2.png")} />
          ) : (
            <ReactSketchCanvas
              style={styles}
              strokeWidth={4}
              strokeColor="black"
            />
          )}
        </Grid>
      </Grid>
      {state ? (
        ""
      ) : (
        <Button
          component={Link}
          variant="contained"
          size="large"
          to="/help/quiz7/1"
        >
          다음
        </Button>
      )}
    </>
  );
}

export { Quiz331, Quiz332 };
