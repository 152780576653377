import { getCookie } from "../../support/Cookie";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz521, Quiz522 } from "./Quiz952";

export default function Quiz9() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz521 />} />
        <Route path="2" element={<Quiz522 />} />
      </Routes>
    </>
  );
}
