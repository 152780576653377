/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { saveResultInspect } from "../../components/Quiz";
import SpeakButton from "../../components/quiz_support/TextToSpeech";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";

import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";

export default function Quiz1() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 1;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };

  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  const Quiz100 = () => {
    useEffect(() => {
      resetCounter();
      resetWin();
      resetLose();
    }, []);

    return (
      <>
        <h1>지남력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map((v, index) => (
              <h2 key={`${index}-${QuizNum}`}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  };

  function Quiz101() {
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/1.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/2.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/3.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/4.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz102() {
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/5.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/6.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/7.png")} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz1/8.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz103() {
    const [value, setValue] = useState("0");
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz1/9.png")} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="정답입력"
              value={value}
              variant="filled"
              inputProps={{ style: { fontSize: 25 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 25 } }} // font size of input label
              type="number"
              onChange={e => {
                setValue(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              value === "5" ? quizWin(pageCount) : quizLose(pageCount);
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  function Quiz104() {
    const [value, setValue] = useState("0");
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz1/10.png")} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="정답입력"
              value={value}
              variant="filled"
              inputProps={{ style: { fontSize: 25 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 25 } }} // font size of input label
              type="number"
              onChange={e => {
                setValue(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (value == "4") {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  function Quiz105() {
    const [value, setValue] = useState("0");
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz1/11.png")} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="정답입력"
              value={value}
              variant="filled"
              inputProps={{ style: { fontSize: 25 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 25 } }} // font size of input label
              type="number"
              onChange={e => {
                setValue(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (value == "2") {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>지남력 증진 활동</h1>
        <h2>
          <b>점수</b>
        </h2>
        <h2>현재 푼 문제 : {quizCount}</h2>
        <h2>맞춘 문제 : {winCount}</h2>
        <h2>틀린 문제 : {loseCount}</h2>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "지남력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              저장하기
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<Quiz100 />} />
        <Route path="1" element={<Quiz101 />} />
        <Route path="2" element={<Quiz102 />} />
        <Route path="3" element={<Quiz103 />} />
        <Route path="4" element={<Quiz104 />} />
        <Route path="5" element={<Quiz105 />} />
        <Route path="6" element={<Score />} />
      </Routes>
    </>
  );
}
