import { useState } from "react";
import axios from "axios";
import { Container, TextField, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const [id, setID] = useState("");
  const [pw, setPW] = useState("");
  const [name, setName] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const navigate = useNavigate();

  const signupBtn = () => {
    if (pwCheck === pw) {
      signUp();
    }
  };
  const signUp = () => {
    const URL = process.env.REACT_APP_API_HOST;
    axios
      .post(`${URL}/api/member/signup`, {
        name,
        username: id,
        password: pw,
      })
      .then(() => {
        alert("가입되었습니다.");
        navigate("/login");
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Container maxWidth="sm">
      <h1>회원가입</h1>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="이름을 입력하세요"
          variant="outlined"
          onChange={e => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="아이디를 입력하세요"
          variant="outlined"
          onChange={e => setID(e.target.value)}
        />
        <TextField
          fullWidth
          label="비밀번호를 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => setPW(e.target.value)}
        />
        <TextField
          fullWidth
          label="비밀번호를 다시 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => setPwCheck(e.target.value)}
        />
        <Button variant="contained" fullWidth size="large" onClick={signupBtn}>
          회원가입
        </Button>
      </Stack>
    </Container>
  );
}
