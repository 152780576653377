import React from "react";
import { Grid } from "@mui/material";

export default function AdminMain() {
  return (
    <Grid item xs={8}>
      <h1>관리자 패널입니다</h1>
    </Grid>
  );
}
