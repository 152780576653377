import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "600px",
};

function Quiz271() {
  return (
    <>
      <h2>아래 그림을 똑같이 따라 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./270/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="green"
            backgroundImage={require("./270/1-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/6"
      >
        다음
      </Button>
    </>
  );
}

function Quiz272() {
  return (
    <>
      <h2>아래 그림을 똑같이 따라 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./270/2.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="green"
            backgroundImage={require("./270/2-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/7"
      >
        다음
      </Button>
    </>
  );
}

function Quiz273() {
  return (
    <>
      <h2>아래 그림을 똑같이 따라 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./270/3.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="green"
            backgroundImage={require("./270/3-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/8"
      >
        다음
      </Button>
    </>
  );
}

function Quiz274() {
  return (
    <>
      <h2>아래 그림을 똑같이 따라 그리세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./270/4.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="green"
            backgroundImage={require("./270/4-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz6/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz271, Quiz272, Quiz273, Quiz274 };
