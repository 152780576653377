import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz141, Quiz142, Quiz143 } from "./Quiz314";
import { Quiz151, Quiz152 } from "./Quiz315";
import { getCookie } from "../../support/Cookie";

export default function Quiz3() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="5" element={<Quiz141 />} />
        <Route path="6" element={<Quiz142 />} />
        <Route path="7" element={<Quiz143 />} />
        <Route path="8" element={<Quiz151 />} />
        <Route path="9" element={<Quiz152 />} />
      </Routes>
    </>
  );
}
