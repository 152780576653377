/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Stack,
  Typography,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import "./Quiz5.sass";
import SpeakButton from "../quiz_support/TextToSpeech";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";

export default function Quiz1() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 5;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  const QuizBottomComponent = ({ pageCount, checkFunc }) => {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc) {
                // 정답 로직
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  function Quiz500() {
    useEffect(
      () => () => {
        resetCounter();
        resetWin();
        resetLose();
      },
      [],
    );
    return (
      <>
        <h1>계산능력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  function Quiz501() {
    const [value, setValue] = useState(0);
    const pageCount = 1;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/1.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                포기
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 5} />
      </>
    );
  }

  function Quiz502() {
    const [value, setValue] = useState(0);
    const pageCount = 2;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/2.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 6} />
      </>
    );
  }

  function Quiz503() {
    const [value, setValue] = useState(0);
    const pageCount = 3;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/3.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 7} />
      </>
    );
  }

  function Quiz504() {
    const [value, setValue] = useState(0);
    const pageCount = 4;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/4.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                포기
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 8} />
      </>
    );
  }

  function Quiz505() {
    const [value, setValue] = useState(0);
    const pageCount = 5;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/5.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 4} />
      </>
    );
  }

  function Quiz506() {
    const [value, setValue] = useState(0);
    const pageCount = 6;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/6.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 3} />
      </>
    );
  }

  function Quiz507() {
    const [value, setValue] = useState(0);
    const pageCount = 7;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/7.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 6} />
      </>
    );
  }

  function Quiz508() {
    const [value, setValue] = useState(0);
    const pageCount = 8;
    const handle = e => {
      setValue(e.target.value);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/8.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "32ch" }}
          onChange={handle}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent pageCount={pageCount} checkFunc={value == 4} />
      </>
    );
  }

  function Quiz509() {
    const [isCorrect, setIsCorrect] = useState({});
    const pageCount = 9;

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Stack sx={{ alignItems: "center" }}>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          6 - 1 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("5")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          8 + 2 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("10")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          7 - 2 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("5")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Stack sx={{ alignItems: "center" }}>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          2 + 4 - 3 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("3")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          5 - 3 + 2 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("4")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          6 + 1 - 5 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "30ch" }}
                    onChange={handleTF("2")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isCorrect).filter(isright => isright == true)
              .length == 5
          }
        />
      </>
    );
  }

  function Quiz510() {
    const [isCorrect, setIsCorrect] = useState({});
    const pageCount = 10;

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][9]}</h2>
        <SpeakButton text={quizPageText[QuizNum][9]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Stack sx={{ alignItems: "center" }}>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          16 - 7 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("9")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          14 + 13 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("27")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          22 - 11 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("11")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Stack sx={{ alignItems: "center" }}>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          15 + 4 - 13 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("6")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          22 - 3 + 12 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("31")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "2rem", color: "black" }}
                        >
                          16 + 14 - 5 =
                        </Typography>
                      </InputAdornment>
                    }
                    type="number"
                    sx={{ m: 1, width: "35ch" }}
                    onChange={handleTF("25")}
                    inputProps={{ style: { fontSize: "2rem" } }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isCorrect).filter(isright => isright == true)
              .length == 6
          }
        />
      </>
    );
  }

  function Quiz511() {
    const [isCorrect, setIsCorrect] = useState("0");
    const pageCount = 11;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][10]}</h2>
        <SpeakButton text={quizPageText[QuizNum][10]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/9.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                마리
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "25ch" }}
          onChange={e => {
            setIsCorrect(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect == "9"}
        />
      </>
    );
  }

  function Quiz512() {
    const [isCorrect, setIsCorrect] = useState("0");
    const pageCount = 12;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][10]}</h2>
        <SpeakButton text={quizPageText[QuizNum][10]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/10.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                정답 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "20ch" }}
          onChange={e => {
            setIsCorrect(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect == "2"}
        />
      </>
    );
  }

  function Quiz513() {
    const [isCorrect1, setIsCorrect1] = useState("0");
    const [isCorrect2, setIsCorrect2] = useState("0");
    const pageCount = 13;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][11]}</h2>
        <SpeakButton text={quizPageText[QuizNum][11]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/11.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                양 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                마리
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "20ch" }}
          onChange={e => {
            setIsCorrect1(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                피망 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                개
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "20ch" }}
          onChange={e => {
            setIsCorrect2(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect1 == "8" && isCorrect2 == "7"}
        />
      </>
    );
  }

  function Quiz514() {
    const [isCorrect1, setIsCorrect1] = useState("0");
    const [isCorrect2, setIsCorrect2] = useState("0");
    const pageCount = 14;

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][12]}</h2>
        <SpeakButton text={quizPageText[QuizNum][12]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz5/12.png")} />
          </Grid>
        </Grid>
        <br />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                소 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                마리
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "20ch" }}
          onChange={e => {
            setIsCorrect1(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                양배추 :
              </Typography>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                포기
              </Typography>
            </InputAdornment>
          }
          type="number"
          sx={{ ml: 1, mr: 1, width: "25ch" }}
          onChange={e => {
            setIsCorrect2(e.target.value);
          }}
          inputProps={{ style: { fontSize: "1.5rem" } }}
        />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect1 == "2" && isCorrect2 == "3"}
        />
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>계산능력 증진 활동</h1>
        <h2>
          <b>점수</b>
        </h2>
        <h2>현재 푼 문제 : {quizCount}</h2>
        <h2>맞춘 문제 : {winCount}</h2>
        <h2>틀린 문제 : {loseCount}</h2>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "계산능력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<Quiz500 />} />
        <Route path="1" element={<Quiz501 />} />
        <Route path="2" element={<Quiz502 />} />
        <Route path="3" element={<Quiz503 />} />
        <Route path="4" element={<Quiz504 />} />
        <Route path="5" element={<Quiz505 />} />
        <Route path="6" element={<Quiz506 />} />
        <Route path="7" element={<Quiz507 />} />
        <Route path="8" element={<Quiz508 />} />
        <Route path="9" element={<Quiz509 />} />
        <Route path="10" element={<Quiz510 />} />
        <Route path="11" element={<Quiz511 />} />
        <Route path="12" element={<Quiz512 />} />
        <Route path="13" element={<Quiz513 />} />
        <Route path="14" element={<Quiz514 />} />
        <Route path="15" element={<Score />} />
      </Routes>
    </>
  );
}
