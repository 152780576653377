import { getCookie } from "../../support/Cookie";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz471, Quiz472, Quiz491, Quiz492 } from "./Quiz847";

export default function Quiz8() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz471 />} />
        <Route path="2" element={<Quiz472 />} />
        <Route path="3" element={<Quiz491 />} />
        <Route path="4" element={<Quiz492 />} />
      </Routes>
    </>
  );
}
