/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";
import SpeakButton from "../quiz_support/TextToSpeech";
export default function Quiz4() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 4;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate("/quiz/" + QuizNum + "/" + ++pageNum);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  const Quiz400 = () => {
    useEffect(() => {
      return () => {
        resetCounter();
        resetWin();
        resetLose();
      };
    }, []);
    return (
      <>
        <h1>기억력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  };

  const Quiz401 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz4/1.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/2.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/3.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/4.png")} />
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz402 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz4/5.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/6.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/7.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/8.png")} />
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz403 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz4/9.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/10.png")} />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/11.png")} />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/12.png")} />
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz404 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz4/13.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/14.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/15.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/16.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz4/17.png")} />
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const ButtonText = ({ children }) => (
    <Typography
      sx={{
        fontSize: "45px",
        fontFamily: "serif",
        fontWeight: "bold",
        margin: "10px",
      }}
    >
      {children}
    </Typography>
  );

  const Quiz405 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]}
        />
        {state ? (
          <img
            style={{ border: "1px solid black" }}
            width="100%"
            src={require("./quiz4/18.png")}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>주전자</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>침대</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>수건</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <ButtonText>젓가락</ButtonText>
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz406 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 6;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]}
        />
        {state ? (
          <img
            style={{ border: "1px solid black" }}
            width="100%"
            src={require("./quiz4/19.png")}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>염소</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>코끼리</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>말</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <ButtonText>토끼</ButtonText>
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz407 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 7;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]}
        />
        {state ? (
          <img
            style={{ border: "1px solid black" }}
            width="100%"
            src={require("./quiz4/20.png")}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <ButtonText>휴대폰</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>세탁기</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>청소기</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>노트북</ButtonText>
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz408 = () => {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 8;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][3] : quizPageText[QuizNum][4]}
        />
        {state ? (
          <img
            style={{ border: "1px solid black" }}
            width="100%"
            src={require("./quiz4/21.png")}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>치마</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <ButtonText>한복</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>스웨터</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <ButtonText>가디건</ButtonText>
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  };

  const Quiz409 = () => {
    const [state, isState] = useState(true);
    const [isCheck, setIsCheck] = useState({});

    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const handle = Id => e => {
      e.preventDefault();
      setIsCheck(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };
    const pageCount = 9;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][6]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][6]}
        />
        {state ? (
          <img
            className="quizImageBorder"
            width="100%"
            src={require("./quiz4/22.png")}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("9")}
                    label="9"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("2")}
                    label="2"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("7")}
                    label="7"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("1")}
                    label="1"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="space-between">
              <Button
                component={Link}
                variant="contained"
                size="large"
                to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
              >
                건너뛰기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const rightValues = Object.values(isCheck);
                  if (
                    rightValues.filter(isright => isright == true).length ==
                      2 &&
                    isCheck["9"] == true &&
                    isCheck["2"] == true
                  ) {
                    quizWin(pageCount);
                  } else {
                    quizLose(pageCount);
                  }
                }}
              >
                정답확인
              </Button>
            </Stack>
          </>
        )}
      </>
    );
  };

  const Quiz410 = () => {
    const [state, isState] = useState(true);
    const [isCheck, setIsCheck] = useState({});

    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const handle = Id => e => {
      e.preventDefault();
      setIsCheck(state => ({
        ...state,
        [Id]: !state[Id],
      }));
    };
    const pageCount = 10;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][6]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][6]}
        />
        {state ? (
          <img
            className="quizImageBorder"
            width="100%"
            src={require("./quiz4/23.png")}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("6")}
                    label="6"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("5")}
                    label="5"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("1")}
                    label="1"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "45px" },
                    }}
                    control={
                      <Checkbox sx={{ transform: "scale(1.5)", mr: "15px" }} />
                    }
                    onChange={handle("8")}
                    label="8"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="space-between">
              <Button
                component={Link}
                variant="contained"
                size="large"
                to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
              >
                건너뛰기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const rightValues = Object.values(isCheck);
                  if (
                    rightValues.filter(isright => isright == true).length ==
                      2 &&
                    isCheck["6"] == true &&
                    isCheck["1"] == true
                  ) {
                    quizWin(pageCount);
                  } else {
                    quizLose(pageCount);
                  }
                }}
              >
                정답확인
              </Button>
            </Stack>
          </>
        )}
      </>
    );
  };

  const Quiz411 = () => {
    const [state, isState] = useState(true);
    const [isCorrect, setIsCorrect] = useState({});

    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };
    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][7]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][7]}
        />
        {state ? (
          <img
            className="quizImageBorder"
            width="100%"
            src={require("./quiz4/24.png")}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("6")}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("3")}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("2")}
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("1")}
                  type="number"
                />
              </Grid>
            </Grid>
            <br />
            <Stack direction="row" justifyContent="space-between">
              <Button
                component={Link}
                variant="contained"
                size="large"
                to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
              >
                건너뛰기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const rightValues = Object.values(isCorrect);
                  if (
                    rightValues.filter(isright => isright == true).length == 4
                  ) {
                    quizWin(pageCount);
                  } else {
                    quizLose(pageCount);
                  }
                }}
              >
                정답확인
              </Button>
            </Stack>
          </>
        )}
      </>
    );
  };

  const Quiz412 = () => {
    const [state, isState] = useState(true);
    const [isCorrect, setIsCorrect] = useState({});

    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);

    const handleTF = Id => e => {
      e.preventDefault();
      if (e.target.value == Id) {
        setIsCorrect(state => ({
          ...state,
          [Id]: true,
        }));
      } else {
        setIsCorrect(state => ({
          ...state,
          [Id]: false,
        }));
      }
    };
    const pageCount = 12;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][7]} </h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][5] : quizPageText[QuizNum][7]}
        />
        {state ? (
          <img
            className="quizImageBorder"
            width="100%"
            src={require("./quiz4/25.png")}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("9")}
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("1")}
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("4")}
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("1")}
                  type="number"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ style: { fontSize: 25 } }}
                  onChange={handleTF("4")}
                  type="number"
                />
              </Grid>
            </Grid>
            <br />
            <Stack direction="row" justifyContent="space-between">
              <Button
                component={Link}
                variant="contained"
                size="large"
                to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
              >
                건너뛰기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const rightValues = Object.values(isCorrect);
                  if (
                    rightValues.filter(isright => isright == true).length == 3
                  ) {
                    quizWin(pageCount);
                  } else {
                    quizLose(pageCount);
                  }
                }}
              >
                정답확인
              </Button>
            </Stack>
          </>
        )}
      </>
    );
  };

  const Score = () => {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>기억력 증진 활동</h1>
        <h2>
          <b>점수</b>
        </h2>
        <h2>현재 푼 문제 : {quizCount}</h2>
        <h2>맞춘 문제 : {winCount}</h2>
        <h2>틀린 문제 : {loseCount}</h2>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "기억력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/" + String(QuizNum + 1) + "/");
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path={"*"} element={<Quiz400 />} />
        <Route path={"1"} element={<Quiz401 />} />
        <Route path={"2"} element={<Quiz402 />} />
        <Route path={"3"} element={<Quiz403 />} />
        <Route path={"4"} element={<Quiz404 />} />
        <Route path={"5"} element={<Quiz405 />} />
        <Route path={"6"} element={<Quiz406 />} />
        <Route path={"7"} element={<Quiz407 />} />
        <Route path={"8"} element={<Quiz408 />} />
        <Route path={"9"} element={<Quiz409 />} />
        <Route path={"10"} element={<Quiz410 />} />
        <Route path={"11"} element={<Quiz411 />} />
        <Route path={"12"} element={<Quiz412 />} />
        <Route path={"13"} element={<Score />} />
      </Routes>
    </>
  );
}
