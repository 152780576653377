import React from "react";
import { Container } from "@mui/material";

export default function LostPage() {
  return (
    <Container maxWidth="sm">
      <h1>페이지가 없습니다</h1>
      <h2>접속하신 페이지가 없습니다.</h2>
    </Container>
  );
}
