/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  Stack,
  Card,
  CardContent,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";
import SpeakButton from "../quiz_support/TextToSpeech";
export default function Quiz1() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 7;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate("/quiz/" + QuizNum + "/" + ++pageNum);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  function QuizBottomComponent({ pageCount, checkFunc }) {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc) {
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  const Quiz700 = () => {
    useEffect(() => {
      return () => {
        resetCounter();
        resetWin();
        resetLose();
      };
    }, []);
    return (
      <>
        <h1>공간지각 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  };

  const OXButton = styled(Button)({
    fontSize: "55px",
    borderRadius: "20px",
    lineHeight: "65px",
    "&.MuiButton-outlined": { border: "3px solid red" },
  });

  const Quiz701 = () => {
    const pageCount = 1;
    const handleChange = e => {
      e.target.value == 1 ? quizWin(pageCount) : quizLose(pageCount);
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>지도를 잘 보고 질문에 답하세요</h2>
        <h3>서울특별시에서 가장 먼 지역은 어디인지 클릭 또는 터치하세요.</h3>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz7/1.png")} />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup onChange={handleChange}>
              <Grid container>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="인천광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="대전광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="대구광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="광주광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="울산광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="부산광역시"
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="제주도"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
        >
          건너뛰기
        </Button>
        <br />
      </>
    );
  };

  const Quiz702 = () => {
    const pageCount = 2;
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz7/2.png")} />
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              딸기
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              수박
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              앵두
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              <img width="100%" src={require("./quiz7/3.png")} />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              <img width="100%" src={require("./quiz7/4.png")} />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              <img width="100%" src={require("./quiz7/5.png")} />
            </OXButton>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 2 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              2 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  };

  const Quiz703 = () => {
    const pageCount = 3;
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz7/6.png")} />
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              메론
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              수박
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              참외
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              <img width="100%" src={require("./quiz7/7.png")} />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              <img width="100%" src={require("./quiz7/8.png")} />
            </OXButton>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              <img width="100%" src={require("./quiz7/9.png")} />
            </OXButton>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 2 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              2 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  };

  const Quiz704 = () => {
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz7/10.png")} />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/11.png")}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/12.png")}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/13.png")}
              />
            </Button>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
        >
          건너뛰기
        </Button>
      </>
    );
  };

  const Quiz705 = () => {
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz7/14.png")} />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/15.png")}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/16.png")}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              <img
                style={{ border: "4px solid black" }}
                width="100%"
                src={require("./quiz7/17.png")}
              />
            </Button>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={"/quiz/" + QuizNum + "/" + String(pageCount + 1)}
        >
          건너뛰기
        </Button>
      </>
    );
  };

  const Quiz706 = () => {
    const pageCount = 6;
    const [isCorrect1, setCorrect1] = useState(false);
    const [isCorrect2, setCorrect2] = useState(false);
    const [isCorrect3, setCorrect3] = useState(false);
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img
              style={{ border: "1px solid black" }}
              width="100%"
              src={require("./quiz7/18.png")}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.4rem", color: "#000" }}
                  >
                    &nbsp;◊
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;&nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              sx={{ ml: 1, mr: 1, mb: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "8" ? setCorrect1(true) : setCorrect1(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem" } }}
              type="number"
            />
            <OutlinedInput
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.2rem", color: "#000" }}
                  >
                    ▭
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;&nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              sx={{ ml: 1, mr: 1, mb: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "8" ? setCorrect2(true) : setCorrect2(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem" } }}
            />
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.2rem", color: "#000" }}
                  >
                    ▼
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              type="number"
              sx={{ ml: 1, mr: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "6" ? setCorrect3(true) : setCorrect3(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem", color: "#000" } }}
            />
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect1 && isCorrect2 && isCorrect3}
        />
      </>
    );
  };

  const Quiz707 = () => {
    const pageCount = 7;
    const [isCorrect1, setCorrect1] = useState(false);
    const [isCorrect2, setCorrect2] = useState(false);
    const [isCorrect3, setCorrect3] = useState(false);
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              style={{ border: "1px solid black" }}
              width="100%"
              src={require("./quiz7/19.png")}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.9rem", color: "#000" }}
                  >
                    ♠
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;&nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              type="number"
              sx={{ ml: 1, mr: 1, mb: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "9" ? setCorrect1(true) : setCorrect1(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem" } }}
            />
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.2rem", color: "#000" }}
                  >
                    ★
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              type="number"
              sx={{ ml: 1, mr: 1, mb: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "9" ? setCorrect2(true) : setCorrect2(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem" } }}
            />
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "2.0rem", color: "#000" }}
                  >
                    ▼
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    &nbsp;모양의 개수 :
                  </Typography>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.5rem", color: "#000" }}
                  >
                    개
                  </Typography>
                </InputAdornment>
              }
              type="number"
              sx={{ ml: 1, mr: 1, width: "32ch" }}
              onChange={e => {
                e.target.value == "7" ? setCorrect3(true) : setCorrect3(false);
              }}
              inputProps={{ style: { fontSize: "1.5rem" } }}
            />
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={isCorrect1 && isCorrect2 && isCorrect3}
        />
      </>
    );
  };

  const Score = () => {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>공간지각 증진 활동</h1>
        <h2>
          <b>점수</b>
        </h2>
        <h2>현재 푼 문제 : {quizCount}</h2>
        <h2>맞춘 문제 : {winCount}</h2>
        <h2>틀린 문제 : {loseCount}</h2>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "공간지각 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route path={"*"} element={<Quiz700 />} />
        <Route path={"1"} element={<Quiz701 />} />
        <Route path={"2"} element={<Quiz702 />} />
        <Route path={"3"} element={<Quiz703 />} />
        <Route path={"4"} element={<Quiz704 />} />
        <Route path={"5"} element={<Quiz705 />} />
        <Route path={"6"} element={<Quiz706 />} />
        <Route path={"7"} element={<Quiz707 />} />
        <Route path={"8"} element={<Score />} />
      </Routes>
    </>
  );
}
