import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "600px",
};

function Quiz261() {
  return (
    <>
      <h2>다음 도형을 잘보고 같은 도형을 찾아 색칠 하세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./260/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./260/1-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz262() {
  return (
    <>
      <h2>다음 도형을 잘보고 같은 도형을 찾아 색칠 하세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./260/2.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="blue"
            backgroundImage={require("./260/2-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/3"
      >
        다음
      </Button>
    </>
  );
}

function Quiz263() {
  return (
    <>
      <h2>다음 도형을 잘보고 같은 도형을 찾아 색칠 하세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./260/3.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="green"
            backgroundImage={require("./260/3-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz264() {
  return (
    <>
      <h2>다음 도형을 잘보고 같은 도형을 찾아 색칠 하세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./260/4.png")} />
        </Grid>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="black"
            backgroundImage={require("./260/4-1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz5/5"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz261, Quiz262, Quiz263, Quiz264 };
