import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz71, Quiz72, Quiz73, Quiz74 } from "./Quiz27";
import { Quiz81, Quiz82, Quiz83, Quiz84 } from "./Quiz28";
import { Quiz91, Quiz92, Quiz93, Quiz94 } from "./Quiz29";
import {
  Quiz101,
  Quiz102,
  Quiz103,
  Quiz104,
  Quiz105,
} from "../../quizhelp/quiz2/Quiz210";
import {
  Quiz111,
  Quiz112,
  Quiz121,
  Quiz122,
  Quiz123,
} from "../../quizhelp/quiz2/Quiz211";
import { getCookie } from "../../support/Cookie";

export default function Quiz2() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz71 />} />
        <Route path="2" element={<Quiz72 />} />
        <Route path="3" element={<Quiz73 />} />
        <Route path="4" element={<Quiz74 />} />
        <Route path="5" element={<Quiz81 />} />
        <Route path="6" element={<Quiz82 />} />
        <Route path="7" element={<Quiz83 />} />
        <Route path="8" element={<Quiz84 />} />
        <Route path="9" element={<Quiz91 />} />
        <Route path="10" element={<Quiz92 />} />
        <Route path="11" element={<Quiz93 />} />
        <Route path="12" element={<Quiz94 />} />
        <Route path="13" element={<Quiz101 />} />
        <Route path="14" element={<Quiz102 />} />
        <Route path="15" element={<Quiz103 />} />
        <Route path="16" element={<Quiz104 />} />
        <Route path="17" element={<Quiz105 />} />
        <Route path="18" element={<Quiz111 />} />
        <Route path="19" element={<Quiz112 />} />
        <Route path="20" element={<Quiz121 />} />
        <Route path="21" element={<Quiz122 />} />
        <Route path="22" element={<Quiz123 />} />
      </Routes>
    </>
  );
}
