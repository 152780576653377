/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, TextField } from "@mui/material";

function Quiz71() {
  return (
    <>
      <h2>다음 그림의 이름과 용도를 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/1.png")} />
        </Grid>
      </Grid>
      <TextField />
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz72() {
  return (
    <>
      <h2>다음 그림의 이름과 용도를 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/2.png")} />
        </Grid>
      </Grid>
      <TextField />
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/3"
      >
        다음
      </Button>
    </>
  );
}

function Quiz73() {
  return (
    <>
      <h2>다음 그림의 이름과 무엇을 할 때 사용하는 물건인지 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/3.png")} />
          <TextField />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/4.png")} />
          <TextField />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz74() {
  return (
    <>
      <h2>다음 그림이 무엇을 하는 그림인지 설명해주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/5.png")} />
          <TextField />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./70/6.png")} />
          <TextField />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/5"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz71, Quiz72, Quiz73, Quiz74 };
