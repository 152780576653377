import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz91() {
  return (
    <>
      <h2>좋아하는 과일에 대해 이야기 해보세요.</h2>
      <Grid item xs={12}>
        <img width="100%" src={require("./90/1.png")} />
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/10"
      >
        다음
      </Button>
    </>
  );
}

function Quiz92() {
  return (
    <>
      <h2>자신의 장점(잘하는 점)에 대해 소개해 주세요.</h2>
      <Grid item xs={12}>
        <img width="100%" src={require("./90/2.png")} />
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/11"
      >
        다음
      </Button>
    </>
  );
}

function Quiz93() {
  return (
    <>
      <h2>좋아하는 음식 3가지와 싫어하는 음식 3가지에 대해 이야기 해주세요.</h2>
      <Grid item xs={12}>
        <img width="100%" src={require("./90/3.png")} />
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/12"
      >
        다음
      </Button>
    </>
  );
}

function Quiz94() {
  return (
    <>
      <h2>지금 하고 싶은 일이나 소원이 있다면 말씀해 주세요.</h2>
      <Grid item xs={12}>
        <img width="100%" src={require("./90/4.png")} />
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/13"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz91, Quiz92, Quiz93, Quiz94 };
