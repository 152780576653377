import React from "react";
import { Link } from "react-router-dom";
import { Grid, TextField, Button } from "@mui/material";
import "./Quiz315.sass";

function Quiz151() {
  return (
    <>
      <h2>
        다음 단어를 큰 소리로 읽어보세요. (글자에 집중)
        <br />
        (유형 1 : 글자와 색깔이 동일)
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./150/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/9"
      >
        다음
      </Button>
    </>
  );
}

function Quiz152() {
  return (
    <>
      <h2>
        다음 단어의 색깔을 큰 소리로 읽어보세요. (색깔에 집중)
        <br />
        (유형 2 : 글자와 색깔이 다름)
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./150/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz4/1"
      >
        다음
      </Button>
    </>
  );
}

function Quiz161() {
  return (
    <>
      <h2>빈칸에 들어갈 숫자를 모두 채워 넣으세요. (유형 1)</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <table>
            <tr>
              <td>1</td>
              <td>
                <TextField />
              </td>
              <td>3</td>
              <td>
                <TextField />
              </td>
              <td>5</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>7</td>
              <td>
                <TextField />
              </td>
              <td>9</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>12</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>15</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>18</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>21</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>24</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>26</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
            </tr>
          </table>
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/11"
      >
        다음
      </Button>
    </>
  );
}

function Quiz162() {
  return (
    <>
      <h2>빈칸에 들어갈 글자를 모두 채워 넣으세요. (유형 2)</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <table>
            <tr>
              <td>ㄱ</td>
              <td>
                <TextField />
              </td>
              <td>ㄷ</td>
              <td>
                <TextField />
              </td>
              <td>ㅁ</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>ㅅ</td>
              <td>
                <TextField />
              </td>
              <td>ㅈ</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>ㅋ</td>
              <td>ㅌ</td>
              <td>
                <TextField />
              </td>
              <td>ㅎ</td>
              <td>ㅏ</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>ㅓ</td>
              <td>
                <TextField />
              </td>
              <td>ㅗ</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>ㅜ</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>ㅣ</td>
              <td>가</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>다</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>사</td>
            </tr>
          </table>
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/12"
      >
        다음
      </Button>
    </>
  );
}

function Quiz163() {
  return (
    <>
      <h2>빈칸에 들어갈 것이 무엇인지 모두 채워 넣으세요. (유형 3)</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <table>
            <tr>
              <td>100</td>
              <td>
                <TextField />
              </td>
              <td>98</td>
              <td>
                <TextField />
              </td>
              <td>96</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>94</td>
              <td>
                <TextField />
              </td>
              <td>92</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>90</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>86</td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>84</td>
              <td>
                <TextField />
              </td>
              <td>82</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>80</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>77</td>
              <td>
                <TextField />
              </td>
            </tr>
            <tr>
              <td>
                <TextField />
              </td>
              <td>74</td>
              <td>
                <TextField />
              </td>
              <td>
                <TextField />
              </td>
              <td>71</td>
            </tr>
          </table>
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/13"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz151, Quiz152, Quiz161, Quiz162, Quiz163 };
