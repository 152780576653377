import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { Container, TextField, Button, Stack, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie, deleteCookie, changeCookie } from "./Cookie";

export default function Login() {
  const [userName, editUserName] = useState();
  const [passWord, editPassWord] = useState();
  const [isGoodtoGo, setGoodtoGo] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (Number(getCookie("role")) > 0) {
      navigate("/quiz");
    }
  });

  const changeUsername = e => {
    editUserName(e.target.value);
  };

  const changePassword = e => {
    editPassWord(e.target.value);
  };

  const onKeyPress = e => {
    if (e.key == "Enter") {
      loginButton();
    }
  };

  const URL = process.env.REACT_APP_API_HOST;

  const loginButton = () => {
    axios
      .post(`${URL}/api/authenticate`, {
        username: userName,
        password: passWord,
      })
      .then(function (response) {
        setGoodtoGo(2);
        deleteCookie("token");
        setCookie("role", "0");
        deleteCookie("username");
        setCookie(
          "token",
          response.data.token.trim().replace(/(\r\n|\n|\r)/gm, ""),
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const decodeToken: any = jwt_decode(response.data.token);
        setCookie("username", decodeToken.sub);
        setCookie("role", "1");

        const roles = decodeToken.auth;
        if (
          roles.split(",", 0) == "ROLE_ADMIN" ||
          roles.split(",", 1) == "ROLE_ADMIN"
        ) {
          changeCookie("role", "2");
          console.log("어드민입니다.");
        }
        navigate("/quiz");
      })
      .catch(function (error) {
        console.log(error);
        setGoodtoGo(1);
      });
  };

  return (
    <Container maxWidth="sm">
      <h1>로그인</h1>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="아이디를 입력하세요"
          variant="outlined"
          onChange={changeUsername}
        />
        <TextField
          fullWidth
          label="비밀번호를 입력하세요"
          type="password"
          variant="outlined"
          onKeyPress={onKeyPress}
          onChange={changePassword}
        />
        {isGoodtoGo == 1 ? (
          <Alert severity="error">
            {" "}
            로그인 실패 - 아이디와 비밀번호를 확인하세요
          </Alert>
        ) : (
          ""
        )}
        {isGoodtoGo == 2 ? (
          <Alert severity="success">로그인 성공 — 페이지를 이동합니다.</Alert>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={loginButton}
        >
          로그인
        </Button>
        <Button
          size="large"
          onClick={() => {
            navigate("/signup");
          }}
        >
          회원가입
        </Button>
      </Stack>
    </Container>
  );
}
