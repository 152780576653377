import { Routes, Route, useNavigate } from "react-router-dom";
import { Quiz211, Quiz212, Quiz213, Quiz214, Quiz215 } from "./Quiz421";
import { getCookie } from "../../support/Cookie";

export default function Quiz4() {
  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }
  return (
    <>
      <h1>도움 받아 해결하기</h1>
      <Routes>
        <Route path="1" element={<Quiz211 />} />
        <Route path="2" element={<Quiz212 />} />
        <Route path="3" element={<Quiz213 />} />
        <Route path="4" element={<Quiz214 />} />
        <Route path="5" element={<Quiz215 />} />
      </Routes>
    </>
  );
}
