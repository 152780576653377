/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  styled,
  Stack,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  ThemeProvider,
  autocompleteClasses,
} from "@mui/material";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import {
  quizInfoText,
  quizPageText,
  quizText9,
  quizTitleText,
} from "./QuizText";
import SpeakButton from "../quiz_support/TextToSpeech";
import { theme } from "../quiz_support/QuizVarient";

export default function Quiz9() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 9;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  // Quiz 하단 부 건너뛰기+정답확인 -> Stack 컴포넌트로 감싼 공통 컴포넌트임
  function QuizBottomComponent({ pageCount, checkFunc }) {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc()) {
                // 정답 로직
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  const OXButton = styled(Button)({
    width: "8.5vw",
    height: "11vh",
    color: "black",
    fontWeight: "bold",
    fontSize: "5rem",
    borderWidth: "2px",
  });

  function Quiz900() {
    useEffect(
      () => () => {
        resetCounter();
        resetWin();
        resetLose();
      },
      [],
    );
    return (
      <>
        <h1>사고조직능력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  function Quiz901() {
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/1.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              ○
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz902() {
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/2.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ◆
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              △
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz903() {
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/3.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ♡
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              ◉
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz904() {
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/4.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ○
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz905() {
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/5.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              △
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              ☆
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ○
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz906() {
    const pageCount = 6;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>

        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/6.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              ☆
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              △
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ◇
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz907() {
    const pageCount = 7;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/7.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ☆
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ○
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz908() {
    const pageCount = 8;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][2]}</h2>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/8.png")}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ♤
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              ☆
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={"auto"}>
            <OXButton
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              △
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz909() {
    const [value, setValue] = useState("");
    const pageCount = 9;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <br />
        <SpeakButton text={quizText9[0]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/9.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">세</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "35") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz910() {
    const [value, setValue] = useState("");
    const pageCount = 10;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <br />
        <SpeakButton text={quizText9[1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/10.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">명</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "3") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz911() {
    const [value, setValue] = useState("");
    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <br />
        <SpeakButton text={quizText9[2]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/11.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">세</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "9") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz912() {
    const [value, setValue] = useState("");
    const pageCount = 12;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <br />
        <SpeakButton text={quizText9[3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/12.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">명</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "6") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz913() {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const pageCount = 13;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <br />
        <SpeakButton text={quizText9[4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/13.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                정답 :
              </Typography>
              <OutlinedInput
                sx={{ ml: 1, mr: 1 }}
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">사과 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
              />
              <OutlinedInput
                type="number"
                sx={{ ml: 1, mr: 1 }}
                onChange={e => {
                  setValue2(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">배 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">개</InputAdornment>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "1" && value2 == "2") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz914() {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const pageCount = 14;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/14.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <SpeakButton text={quizPageText[QuizNum][5]} />
            <h3>
              ㉠에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉡에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue2(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉠과 ㉡ 중 큰 수에서 작은 수를 뺀 값은 얼마입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue3(e.target.value);
                }}
              />
            </h3>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "19" && value2 == "13" && value3 == "6") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz915() {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const pageCount = 15;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/15.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <SpeakButton text={quizPageText[QuizNum][5]} />
            <h3>
              ㉠에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉡에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue2(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉠과 ㉡ 중 큰 수에서 작은 수를 뺀 값은 얼마입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue3(e.target.value);
                }}
              />
            </h3>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "26" && value2 == "34" && value3 == "8") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz916() {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const pageCount = 16;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz9/16.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <SpeakButton text={quizPageText[QuizNum][6]} />
            <h3>
              ㉠에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉡에 들어갈 숫자는 무엇입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue2(e.target.value);
                }}
              />
            </h3>
            <h3>
              ㉠에서 ㉡을 뺀 후 ㉢을 더한 값은 얼마입니까?{" "}
              <TextField
                type="number"
                onChange={e => {
                  setValue3(e.target.value);
                }}
              />
            </h3>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "41" && value2 == "34" && value3 == "37") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>사고조직력 증진 활동</h1>
        <h2>점수</h2>
        <h3>현재 푼 문제 : {quizCount}</h3>
        <h3>맞춘 문제 : {winCount}</h3>
        <h3>틀린 문제 : {loseCount}</h3>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "사고조직능력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<Quiz900 />} />
          <Route path="1" element={<Quiz901 />} />
          <Route path="2" element={<Quiz902 />} />
          <Route path="3" element={<Quiz903 />} />
          <Route path="4" element={<Quiz904 />} />
          <Route path="5" element={<Quiz905 />} />
          <Route path="6" element={<Quiz906 />} />
          <Route path="7" element={<Quiz907 />} />
          <Route path="8" element={<Quiz908 />} />
          <Route path="9" element={<Quiz909 />} />
          <Route path="10" element={<Quiz910 />} />
          <Route path="11" element={<Quiz911 />} />
          <Route path="12" element={<Quiz912 />} />
          <Route path="13" element={<Quiz913 />} />
          <Route path="14" element={<Quiz914 />} />
          <Route path="15" element={<Quiz915 />} />
          <Route path="16" element={<Quiz916 />} />
          <Route path="17" element={<Score />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
