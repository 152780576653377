/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  styled,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Stack,
  Card,
  CardContent,
  ThemeProvider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";
import SpeakButton from "../quiz_support/TextToSpeech";
import { theme } from "../quiz_support/QuizVarient";

export default function Quiz8() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 8;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  // Quiz 하단 부 건너뛰기+정답확인 -> Stack 컴포넌트로 감싼 공통 컴포넌트임
  function QuizBottomComponent({ pageCount, checkFunc }) {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc()) {
                // 정답 로직
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  }

  const OXButton = styled(Button)({
    fontSize: "2.3rem",
    "&:hover, &.MuiButton-outlined": {
      border: "3px solid red",
    },
  }); // styled

  function Quiz800() {
    useEffect(
      () => () => {
        resetCounter();
        resetWin();
        resetLose();
      },
      [],
    );
    return (
      <>
        <h1>문제해결력 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  function Quiz801() {
    const pageCount = 1;
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };

    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz8/1.png")} />
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              포도
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              수박
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              딸기
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              참외
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              체리
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              망고
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
            >
              복숭아
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              자두
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              키위
            </OXButton>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            const rightValues = Object.values(isClick);
            const failedValues = Object.values(isNot);
            if (
              rightValues.length == 3 &&
              rightValues.filter(isright => isright == true).length == 3 &&
              failedValues.filter(isnot => isnot == true).length < 1
            ) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz802() {
    const pageCount = 2;
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/2.png")}
            />
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
            >
              바나나
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
            >
              감귤
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
            >
              딸기
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
            >
              오렌지
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
            >
              체리
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
            >
              사과
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
            >
              메론
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
            >
              자두
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
            >
              유자
            </OXButton>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            const rightValues = Object.values(isClick);
            const failedValues = Object.values(isNot);
            if (
              rightValues.length == 3 &&
              rightValues.filter(isright => isright == true).length == 3 &&
              failedValues.filter(isnot => isnot == true).length < 1
            ) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz803() {
    const [value, setValue] = useState("0");
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>아래의 가격표를 보고 질문에 답 하세요</h2>
        <h3>
          돋보기 한 개와 메모장 한 개를 구입하려고 합니다. 비용은 얼마일까요?
        </h3>
        <SpeakButton text={quizPageText[QuizNum][2]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/3.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">원</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "1900") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz804() {
    const [value, setValue] = useState("0");
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>아래의 가격표를 보고 질문에 대한 답을 작성하세요</h2>
        <h3>
          새우볶음밥 1그릇과 잡채밥 2그릇을 먹었습니다. 얼마가 필요합니까?
        </h3>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/4.png")}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <OutlinedInput
                type="number"
                onChange={e => {
                  setValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">정 답 :</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">원</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value == "23500") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz805() {
    const [value1, setValue1] = useState("0");
    const [value2, setValue2] = useState("0");
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][4]}</h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <br />
        <SpeakButton text={quizPageText[QuizNum][10]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/5.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                정답 :
              </Typography>
              <OutlinedInput
                sx={{ ml: 1, mr: 1 }}
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">월</InputAdornment>
                }
              />
              <OutlinedInput
                type="number"
                sx={{ ml: 1, mr: 1 }}
                onChange={e => {
                  setValue2(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">일</InputAdornment>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "10" && value2 == "26") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz806() {
    const [value1, setValue1] = useState("0");
    const [value2, setValue2] = useState("0");
    const [value3, setValue3] = useState("");
    const pageCount = 6;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][5]}</h2>
        <SpeakButton text={quizPageText[QuizNum][5]} />
        <br />
        <SpeakButton text={quizPageText[QuizNum][11]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/6.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                정답 :
              </Typography>
              <OutlinedInput
                sx={{ ml: 1, mr: 1 }}
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">월</InputAdornment>
                }
              />
              <OutlinedInput
                type="number"
                sx={{ ml: 1, mr: 1 }}
                onChange={e => {
                  setValue2(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">일</InputAdornment>
                }
              />
              <FormControl>
                <InputLabel>요일</InputLabel>
                <Select
                  label="요일"
                  sx={{ ml: 1, mr: 1, minWidth: "90px" }}
                  onChange={(e: SelectChangeEvent) => {
                    setValue3(e.target.value);
                  }}
                  value={value3}
                >
                  <MenuItem value={"월"}>월</MenuItem>
                  <MenuItem value={"화"}>화</MenuItem>
                  <MenuItem value={"수"}>수</MenuItem>
                  <MenuItem value={"목"}>목</MenuItem>
                  <MenuItem value={"금"}>금</MenuItem>
                  <MenuItem value={"토"}>토</MenuItem>
                  <MenuItem value={"일"}>일</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "1" && value2 == "29" && value3 == "화") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz807() {
    const [value1, setValue1] = useState("0");
    const [value2, setValue2] = useState("0");
    const [value3, setValue3] = useState("");
    const pageCount = 7;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][5]}</h2>
        <SpeakButton text={quizPageText[QuizNum][5]} />
        <br />
        <SpeakButton text={quizPageText[QuizNum][12]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/7.png")}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              sx={{
                mt: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ marginRight: "10px", whiteSpace: "nowrap" }}
                variant="body1"
              >
                정답 :
              </Typography>
              <OutlinedInput
                sx={{ ml: 1, mr: 1 }}
                type="number"
                onChange={e => {
                  setValue1(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">월</InputAdornment>
                }
              />
              <OutlinedInput
                type="number"
                sx={{ ml: 1, mr: 1 }}
                onChange={e => {
                  setValue2(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">일</InputAdornment>
                }
              />
              <FormControl>
                <InputLabel>요일</InputLabel>
                <Select
                  label="요일"
                  sx={{ ml: 1, mr: 1, minWidth: "90px" }}
                  onChange={(e: SelectChangeEvent) => {
                    setValue3(e.target.value);
                  }}
                  value={value3}
                >
                  <MenuItem value={"월"}>월</MenuItem>
                  <MenuItem value={"화"}>화</MenuItem>
                  <MenuItem value={"수"}>수</MenuItem>
                  <MenuItem value={"목"}>목</MenuItem>
                  <MenuItem value={"금"}>금</MenuItem>
                  <MenuItem value={"토"}>토</MenuItem>
                  <MenuItem value={"일"}>일</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 == "12" && value2 == "25" && value3 == "월") {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz808() {
    const [value1, setValue1] = useState(false);
    const [value2, setValue2] = useState(false);
    const pageCount = 8;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>그림을 보고 다음의 질문에 답하시오</h2>
        <h3>
          양치를 하기 위해 필요한 것을 모두 찾아 클릭 또는 터치 하세요 (2가지)
        </h3>
        <SpeakButton text={quizPageText[QuizNum][6]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/8.png")}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="세탁기" />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="지갑" />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    setValue1(e.target.checked);
                  }}
                />
              }
              label="칫솔"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="라면" />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    setValue2(e.target.checked);
                  }}
                />
              }
              label="치약"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="비누" />
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 && value2) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz809() {
    const [value1, setValue1] = useState(false);
    const [value2, setValue2] = useState(false);
    const pageCount = 9;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>그림을 보고 다음의 질문에 답하시오</h2>
        <h3>
          배드민턴을 치기 위해 필요한 것을 모두 찾아 클릭 또는 터치 하세요
          (2가지)
        </h3>
        <SpeakButton text={quizPageText[QuizNum][7]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz8/9.png")}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    setValue1(e.target.checked);
                  }}
                />
              }
              label="라켓"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="실" />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="칫솔" />
          </Grid>

          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="수건" />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    setValue2(e.target.checked);
                  }}
                />
              }
              label="배드민턴 공"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControlLabel control={<Checkbox />} label="드라이버" />
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            if (value1 && value2) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz810() {
    const [value, setValue] = useState({});
    const handleChange = Id => e => {
      e.preventDefault();
      setValue(state => ({
        ...state,
        [Id]: e.target.value,
      }));
    };
    const pageCount = 10;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][4]}</h1>
        <h2>다음의 속담구절에 알맞은 단어를 찾아 클릭 또는 터치하세요</h2>
        <SpeakButton text={quizPageText[QuizNum][8]} />
        <br />
        <SpeakButton text={quizPageText[QuizNum][13]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>
              1. 낫 놓고{" "}
              <Select label="선택하기" onChange={handleChange(1)}>
                <MenuItem value={1}>기역자</MenuItem>
                <MenuItem value={0}>니은자</MenuItem>
                <MenuItem value={0}>디귿자</MenuItem>
                <MenuItem value={0}>리을자</MenuItem>
                <MenuItem value={0}>미음자</MenuItem>
              </Select>{" "}
              도 모른다.
            </h3>
            <h3>
              2. 닭 쫓던{" "}
              <Select label="선택하기" onChange={handleChange(2)}>
                <MenuItem value={0}>곰</MenuItem>
                <MenuItem value={0}>꿩</MenuItem>
                <MenuItem value={0}>매</MenuItem>
                <MenuItem value={1}>개</MenuItem>
                <MenuItem value={0}>소</MenuItem>
              </Select>{" "}
              지붕 쳐다본다.
            </h3>
            <h3>
              3.{" "}
              <Select label="선택하기" onChange={handleChange(3)}>
                <MenuItem value={0}>찻잔</MenuItem>
                <MenuItem value={0}>촛불</MenuItem>
                <MenuItem value={1}>등잔</MenuItem>
                <MenuItem value={0}>그늘</MenuItem>
                <MenuItem value={0}>다리</MenuItem>
              </Select>{" "}
              밑이 어둡다.
            </h3>
            <h3>
              4.{" "}
              <Select label="선택하기" onChange={handleChange(4)}>
                <MenuItem value={0}>장맛비</MenuItem>
                <MenuItem value={1}>가랑비</MenuItem>
                <MenuItem value={0}>소나기</MenuItem>
                <MenuItem value={0}>장대비</MenuItem>
                <MenuItem value={0}>이슬비</MenuItem>
              </Select>
              에 옷 젖는줄 모른다.
            </h3>
            <h3>
              5. 가지 많은 나무에{" "}
              <Select label="선택하기" onChange={handleChange(5)}>
                <MenuItem value={0}>열매</MenuItem>
                <MenuItem value={0}>잎새</MenuItem>
                <MenuItem value={1}>바람</MenuItem>
                <MenuItem value={0}>그네</MenuItem>
                <MenuItem value={0}>벌레</MenuItem>
              </Select>{" "}
              잘 날이 없다.
            </h3>
          </Grid>
        </Grid>

        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            const rightValues = Object.values(value);
            if (
              rightValues.length == 5 &&
              rightValues.filter(isright => isright == 1).length == 5
            ) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz811() {
    const [value, setValue] = useState({});
    const handleChange = Id => e => {
      e.preventDefault();
      setValue(state => ({
        ...state,
        [Id]: e.target.value,
      }));
    };
    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][4]}</h1>
        <h2>다음의 속담구절에 알맞은 단어를 찾아 클릭 또는 터치하세요</h2>
        <SpeakButton text={quizPageText[QuizNum][8]} />
        <br />
        <SpeakButton text={quizPageText[QuizNum][14]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>
              1.{" "}
              <Select label="선택하기" onChange={handleChange(1)}>
                <MenuItem value={1}>밑</MenuItem>
                <MenuItem value={0}>윗</MenuItem>
                <MenuItem value={0}>못</MenuItem>
                <MenuItem value={0}>쌀</MenuItem>
                <MenuItem value={0}>이</MenuItem>
              </Select>{" "}
              빠진 독에{" "}
              <Select label="선택하기" onChange={handleChange(2)}>
                <MenuItem value={0}>장</MenuItem>
                <MenuItem value={0}>쌀</MenuItem>
                <MenuItem value={0}>콩</MenuItem>
                <MenuItem value={1}>물</MenuItem>
                <MenuItem value={0}>불</MenuItem>
              </Select>{" "}
              붓기
            </h3>
            <h3>
              2.{" "}
              <Select label="선택하기" onChange={handleChange(3)}>
                <MenuItem value={0}>잔반</MenuItem>
                <MenuItem value={0}>바람</MenuItem>
                <MenuItem value={1}>바늘</MenuItem>
                <MenuItem value={0}>토끼</MenuItem>
                <MenuItem value={0}>겨울</MenuItem>
              </Select>{" "}
              가는데{" "}
              <Select label="선택하기" onChange={handleChange(4)}>
                <MenuItem value={0}>개</MenuItem>
                <MenuItem value={0}>연</MenuItem>
                <MenuItem value={1}>실</MenuItem>
                <MenuItem value={0}>매</MenuItem>
                <MenuItem value={0}>봄</MenuItem>
              </Select>{" "}
              간다.
            </h3>
            <h3>
              3.{" "}
              <Select label="선택하기" onChange={handleChange(5)}>
                <MenuItem value={0}>파</MenuItem>
                <MenuItem value={0}>해</MenuItem>
                <MenuItem value={1}>피</MenuItem>
                <MenuItem value={0}>자</MenuItem>
                <MenuItem value={0}>초</MenuItem>
              </Select>{" "}
              는{" "}
              <Select label="선택하기" onChange={handleChange(6)}>
                <MenuItem value={0}>불</MenuItem>
                <MenuItem value={0}>달</MenuItem>
                <MenuItem value={1}>물</MenuItem>
                <MenuItem value={0}>말</MenuItem>
                <MenuItem value={0}>시</MenuItem>
              </Select>{" "}
              보다 진하다.
            </h3>
            <h3>
              4. 고생 끝에{" "}
              <Select label="선택하기" onChange={handleChange(7)}>
                <MenuItem value={0}>녹</MenuItem>
                <MenuItem value={1}>낙</MenuItem>
                <MenuItem value={0}>말</MenuItem>
                <MenuItem value={0}>벌</MenuItem>
                <MenuItem value={0}>날</MenuItem>
              </Select>
              이 온다.
            </h3>
            <h3>
              5. 돌다리도{" "}
              <Select label="선택하기" onChange={handleChange(8)}>
                <MenuItem value={1}>두들겨</MenuItem>
                <MenuItem value={0}>다듬어</MenuItem>
                <MenuItem value={0}>보듬어</MenuItem>
                <MenuItem value={0}>문질러</MenuItem>
                <MenuItem value={0}>헤아려</MenuItem>
              </Select>
              보고 건너라.
            </h3>
          </Grid>
        </Grid>
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={() => {
            const rightValues = Object.values(value);
            if (
              rightValues.length == 8 &&
              rightValues.filter(isright => isright == 1).length == 8
            ) {
              return true;
            } else {
              return false;
            }
          }}
        />
      </>
    );
  }

  function Quiz812() {
    const pageCount = 12;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][5]}</h1>
        <h2>
          다음의 저울을 비교하여 가장 무거운 도형을 찾아 클릭 또는 터치하세요
        </h2>
        <SpeakButton text={quizPageText[QuizNum][9]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz8/12.png")} />
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              사각형
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              삼각형
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              원
            </Button>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }
  function Quiz813() {
    const pageCount = 13;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][5]}</h1>
        <h2>
          다음의 저울을 비교하여 가장 무거운 도형을 찾아 클릭 또는 터치하세요
        </h2>
        <SpeakButton text={quizPageText[QuizNum][9]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz8/13.jpg")} />
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              사각형
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizLose(pageCount);
              }}
            >
              삼각형
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={{
                "&.MuiButton-outlined": { width: "100%" },
                fontSize: "1.5rem",
              }}
              variant="outlined"
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              &nbsp;원
            </Button>
          </Grid>
        </Grid>
        <br />
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>문제해결력 증진 활동</h1>
        <h2>점수</h2>
        <h3>현재 푼 문제 : {quizCount}</h3>
        <h3>맞춘 문제 : {winCount}</h3>
        <h3>틀린 문제 : {loseCount}</h3>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "문제해결력 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<Quiz800 />} />
          <Route path="1" element={<Quiz801 />} />
          <Route path="2" element={<Quiz802 />} />
          <Route path="3" element={<Quiz803 />} />
          <Route path="4" element={<Quiz804 />} />
          <Route path="5" element={<Quiz805 />} />
          <Route path="6" element={<Quiz806 />} />
          <Route path="7" element={<Quiz807 />} />
          <Route path="8" element={<Quiz808 />} />
          <Route path="9" element={<Quiz809 />} />
          <Route path="10" element={<Quiz810 />} />
          <Route path="11" element={<Quiz811 />} />
          <Route path="12" element={<Quiz812 />} />
          <Route path="13" element={<Quiz813 />} />
          <Route path="14" element={<Score />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
