import { Button, Grid, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function QuizList() {
  const navigator = useNavigate();
  function ClickNavigate(num: number) {
    navigator(`/quiz/${num}`);
  }
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <h1 style={{ margin: "1px", fontSize: "4vh" }}>Quiz List Screen</h1>
        <List>
          <h3
            style={{
              textAlign: "center",
              fontSize: "3vh",
              marginTop: "10px",
              borderBottom: "3px solid black",
            }}
          >
            도움받아 해결하기
          </h3>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                navigator("/help/quiz1/0");
              }}
            >
              시작하기
            </Button>
          </ListItem>
          <h3
            style={{
              textAlign: "center",
              fontSize: "3vh",
              borderBottom: "3px solid black",
            }}
          >
            혼자서 해결하기
          </h3>

          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(1);
              }}
            >
              지남력 증진 활동
            </Button>
          </ListItem>

          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(1);
              }}
            >
              지남력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(3);
              }}
            >
              집중력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(4);
              }}
            >
              기억력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(5);
              }}
            >
              계산능력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(6);
              }}
            >
              시지각 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(7);
              }}
            >
              공간지각 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(8);
              }}
            >
              문제해결력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(9);
              }}
            >
              사고조직력 증진 활동
            </Button>
          </ListItem>
          <ListItem>
            <Button
              style={{ width: "100%", fontSize: "2vh" }}
              size="large"
              variant="contained"
              onClick={() => {
                ClickNavigate(10);
              }}
            >
              실행능력 증진 활동
            </Button>
          </ListItem>
        </List>
      </Grid>
    </>
  );
}
