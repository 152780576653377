/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { saveResultInspect } from "../Quiz";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from "recoil";
import {
  countQuizState,
  countQuizWin,
  countQuizLose,
} from "../quiz_support/recoilsupport";
import SpeakButton from "../quiz_support/TextToSpeech";
import { quizInfoText, quizPageText, quizTitleText } from "./QuizText";

export default function Quiz6() {
  const resetCounter = useResetRecoilState(countQuizState);
  const resetWin = useResetRecoilState(countQuizWin);
  const resetLose = useResetRecoilState(countQuizLose);
  const countHandler = useSetRecoilState(countQuizState);
  const winHandler = useSetRecoilState(countQuizWin);
  const loseHandler = useSetRecoilState(countQuizLose);
  const winCount = useRecoilValue(countQuizWin);
  const loseCount = useRecoilValue(countQuizLose);
  const quizCount = useRecoilValue(countQuizState);
  const QuizNum = 6;

  const navigate = useNavigate();
  const pageNext = pageNum => {
    navigate(`/quiz/${QuizNum}/${++pageNum}`);
  };
  const quizWin = pageNum => {
    winHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("정답입니다");
    pageNext(pageNum);
  };

  const quizLose = pageNum => {
    loseHandler(pre => pre + 1);
    countHandler(pre => pre + 1);
    alert("틀렸습니다");
    pageNext(pageNum);
  };

  const OXButton = styled(Button)({
    fontSize: "80px",
    height: "80px",
    padding: "3px",
    borderRadius: "20px",
    margin: "3px",
    lineHeight: "65px",
    "&.MuiButton-outlined": { border: "3px solid red" },
  });

  const QuizBottomComponent = ({ pageCount, checkFunc }) => {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              if (checkFunc) {
                // 정답 로직
                quizWin(pageCount);
              } else {
                quizLose(pageCount);
              }
            }}
          >
            정답확인
          </Button>
        </Stack>
      </>
    );
  };

  function Quiz600() {
    useEffect(
      () => () => {
        resetCounter();
        resetWin();
        resetLose();
      },
      [],
    );
    return (
      <>
        <h1>시지각 증진 활동</h1>
        <h2>{quizInfoText[QuizNum]}</h2>
        <Stack direction="row" justifyContent="space-between">
          <SpeakButton text={quizInfoText[QuizNum]} />
          <Button component={Link} variant="contained" size="large" to="1">
            시작
          </Button>
        </Stack>
        <br />
        <br />
        <Card variant="outlined">
          <CardContent>
            {quizTitleText[QuizNum].map(v => (
              <h2 key={v}>프로그램 : {v}</h2>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  function Quiz601() {
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][pageCount]}</h2>
        <SpeakButton text={quizPageText[QuizNum][pageCount]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/1.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz602() {
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/2.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz603() {
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/3.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz604() {
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 활동명 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/4.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz605() {
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 활동명 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/5.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz606() {
    const pageCount = 6;
    return (
      <>
        <h1>프로그램 활동명 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{quizPageText[QuizNum][1]}</h2>
        <SpeakButton text={quizPageText[QuizNum][1]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                quizWin(pageCount);
              }}
            >
              <img width="100%" src={require("./quiz6/6.png")} />
            </Button>
          </Grid>
        </Grid>
        <Button
          component={Link}
          variant="contained"
          size="large"
          to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
        >
          건너뛰기
        </Button>
      </>
    );
  }

  function Quiz607() {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 1;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}</h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz6/7.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/8.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/9.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/10.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/11.png")} />
              </Button>
            </Grid>
          </Grid>
        )}

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  }

  function Quiz608() {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 2;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}</h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz6/12.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/13.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/14.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/15.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/16.png")} />
              </Button>
            </Grid>
          </Grid>
        )}
        <br />

        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  }

  function Quiz609() {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 3;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}</h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz6/17.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/19.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/20.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/21.png")} />
              </Button>
            </Grid>
          </Grid>
        )}
        <br />
        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  }

  function Quiz610() {
    const [state, isState] = useState(true);
    useEffect(() => {
      const timerId = setTimeout(() => {
        isState(false);
      }, 5000);
    }, []);
    const pageCount = 4;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][0]}</h1>
        <h2>{state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}</h2>
        <SpeakButton
          text={state ? quizPageText[QuizNum][1] : quizPageText[QuizNum][2]}
        />
        {state ? (
          <img width="100%" src={require("./quiz6/22.png")} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizWin(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/23.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/24.png")} />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  quizLose(pageCount);
                }}
              >
                <img width="100%" src={require("./quiz6/25.png")} />
              </Button>
            </Grid>
          </Grid>
        )}
        <br />
        {state ? (
          ""
        ) : (
          <Button
            component={Link}
            variant="contained"
            size="large"
            to={`/quiz/${QuizNum}/${String(pageCount + 1)}`}
          >
            건너뛰기
          </Button>
        )}
      </>
    );
  }

  function Quiz611() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 5;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              style={{ border: "1px solid black" }}
              src={require("./quiz6/26.png")}
            />
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              color="error"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
              color="error"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              color="error"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              color="error"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              color="inherit"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
              color="error"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
              color="error"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
              color="error"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
              color="inherit"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              color="error"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
              color="inherit"
            >
              ▲
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 3 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              3 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz612() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 6;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              style={{ border: "1px solid black" }}
              width="100%"
              src={require("./quiz6/27.png")}
            />
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              sx={{ color: "blue" }}
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              sx={{ color: "blue" }}
            >
              ♢
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              sx={{ color: "blue" }}
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              sx={{ color: "blue" }}
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
              sx={{ color: "blue" }}
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
              sx={{ color: "blue" }}
            >
              ♢
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["5"] ? "outlined" : "text"}
              onClick={handleBtn("5")}
              sx={{ color: "blue" }}
            >
              ♦
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 5 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              5 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz613() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 7;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz6/28.png")} />
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              sx={{ color: "blue" }}
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
              color="inherit"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="error"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              color="success"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              color="success"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              color="error"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              sx={{ color: "blue" }}
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
              sx={{ color: "blue" }}
            >
              △
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
              sx={{ color: "blue" }}
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
              color="error"
            >
              △
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
              sx={{ color: "blue" }}
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["16"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("16")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["17"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("17")}
              color="success"
            >
              ▲
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
              color="error"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              sx={{ color: "blue" }}
            >
              ▲
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 4 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              4 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz614() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 8;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][1]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img width="100%" src={require("./quiz6/29.png")} />
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              sx={{ color: "blue" }}
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              color="inherit"
            >
              ■
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              sx={{ color: "blue" }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              color="success"
            >
              ♢
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="success"
            >
              ♦
            </OXButton>
          </Grid>

          <Grid item xs={2}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              sx={{ color: "blue" }}
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>

          <Grid item xs={2}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
              color="error"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={2}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
              sx={{ color: "blue" }}
            >
              □
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["11"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("11")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isNot["10"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("10")}
              color="secondary"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["12"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("12")}
              color="error"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["13"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("13")}
              color="inherit"
            >
              ○
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["14"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("14")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isClick["5"] ? "outlined" : "text"}
              onClick={handleBtn("5")}
              color="inherit"
            >
              ●
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="secondary"
            >
              ♦
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["6"] ? "outlined" : "text"}
              onClick={handleBtn("6")}
              color="success"
            >
              ♢
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["15"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("15")}
              color="inherit"
            >
              ♦
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 6 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              6 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz615() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 9;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>
          {quizPageText[QuizNum][4]}
          <br />
          (모양만 고려, 색상 무시)
        </h2>
        <SpeakButton text={quizPageText[QuizNum][4]} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              width="100%"
              style={{ border: "1px solid black" }}
              src={require("./quiz6/30.png")}
            />
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              color="inherit"
            >
              ☉
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              color="warning"
            >
              ☪
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              color="success"
            >
              ☉
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["3"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("3")}
              color="secondary"
            >
              ☯
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              color="success"
            >
              ☪
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="primary"
            >
              ☉
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="inherit"
            >
              ☉
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              color="primary"
            >
              ☪
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="inherit"
            >
              ☯
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
              color="warning"
            >
              ☪
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              color="error"
            >
              ☉
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              color="warning"
            >
              ☯
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 4 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              4 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz616() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 10;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][2]}</h1>
        <h2>{quizPageText[QuizNum][3]}</h2>
        <SpeakButton text={quizPageText[QuizNum][3]} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              border: "1px solid black",
              mt: 2,
            }}
          >
            <Typography
              variant="h1"
              align="center"
              color="primary"
              sx={{ width: "100%" }}
            >
              ☦
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              color="error"
            >
              ☨
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              color="primary"
            >
              ☥
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              color="primary"
            >
              ☦
            </OXButton>
          </Grid>

          <Grid item xs={3}>
            <OXButton
              variant={isNot["4"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("4")}
              color="inherit"
            >
              ☥
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["5"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("5")}
              color="primary"
            >
              ☨
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["6"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("6")}
              color="secondary"
            >
              ☥
            </OXButton>
          </Grid>
          <Grid item xs={3}>
            <OXButton
              variant={isNot["7"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("7")}
              color="success"
            >
              ☦
            </OXButton>
          </Grid>

          <Grid item xs={4}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              color="primary"
            >
              ☦
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["8"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("8")}
              color="inherit"
            >
              ☨
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["9"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("9")}
              color="error"
            >
              ☨
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 2 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              2 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Quiz617() {
    const [isClick, setIsClick] = useState({});
    const [isNot, setIsNot] = useState({});
    const handleBtn = btnId => e => {
      e.preventDefault();
      setIsClick(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const handleBtnNotRight = btnId => e => {
      e.preventDefault();
      setIsNot(state => ({
        ...state,
        [btnId]: !state[btnId],
      }));
    };
    const pageCount = 11;
    return (
      <>
        <h1>프로그램 : {quizTitleText[QuizNum][3]}</h1>
        <h2>{quizPageText[QuizNum][5]}</h2>
        <SpeakButton text={quizPageText[QuizNum][5]} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img
              className="quizImageBorder"
              width="100%"
              src={require("./quiz6/32.png")}
            />
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["1"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("1")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/33.png")}
              />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["1"] ? "outlined" : "text"}
              onClick={handleBtn("1")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/34.png")}
              />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["2"] ? "outlined" : "text"}
              onClick={handleBtn("2")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/35.png")}
              />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["3"] ? "outlined" : "text"}
              onClick={handleBtn("3")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/36.png")}
              />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isClick["4"] ? "outlined" : "text"}
              onClick={handleBtn("4")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/37.png")}
              />
            </OXButton>
          </Grid>
          <Grid item xs={4}>
            <OXButton
              variant={isNot["2"] ? "outlined" : "text"}
              onClick={handleBtnNotRight("2")}
              sx={{ height: "160px" }}
            >
              <img
                className="quizImageBorder"
                width="100%"
                src={require("./quiz6/38.png")}
              />
            </OXButton>
          </Grid>
        </Grid>
        <br />
        <br />
        <QuizBottomComponent
          pageCount={pageCount}
          checkFunc={
            Object.values(isClick).length == 4 &&
            Object.values(isClick).filter(isright => isright == true).length ==
              4 &&
            Object.values(isNot).filter(isnot => isnot == true).length < 1
          }
        />
      </>
    );
  }

  function Score() {
    const [state, setState] = useState(true);
    return (
      <>
        <h1>시지각 증진 활동</h1>
        <h2>점수</h2>
        <h3>현재 푼 문제 : {quizCount}</h3>
        <h3>맞춘 문제 : {winCount}</h3>
        <h3>틀린 문제 : {loseCount}</h3>
        <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
          {state && quizCount > 0 ? (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                saveResultInspect(
                  "시지각 증진 활동 결과",
                  quizCount,
                  winCount,
                  loseCount,
                );
                setState(false);
              }}
            >
              점수저장
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigate("/quiz/");
            }}
          >
            그만하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/quiz/${String(QuizNum + 1)}/`);
            }}
          >
            이어풀기
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<Quiz600 />} />
        {/* <Route path="1" element={<Quiz601 />} />
        <Route path="2" element={<Quiz602 />} />
        <Route path="3" element={<Quiz603 />} />
        <Route path="4" element={<Quiz604 />} />
        <Route path="5" element={<Quiz605 />} />
  <Route path="6" element={<Quiz606 />} > */}
        <Route path="1" element={<Quiz607 />} />
        <Route path="2" element={<Quiz608 />} />
        <Route path="3" element={<Quiz609 />} />
        <Route path="4" element={<Quiz610 />} />
        <Route path="5" element={<Quiz611 />} />
        <Route path="6" element={<Quiz612 />} />
        <Route path="7" element={<Quiz613 />} />
        <Route path="8" element={<Quiz614 />} />
        <Route path="9" element={<Quiz615 />} />
        <Route path="10" element={<Quiz616 />} />
        <Route path="11" element={<Quiz617 />} />
        <Route path="12" element={<Score />} />
      </Routes>
    </>
  );
}
