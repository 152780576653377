import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "900px",
};

function Quiz521() {
  return (
    <>
      <h2>관련있는 그림끼리 선을 연결하세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./52/1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz9/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz522() {
  return (
    <>
      <h2>관련있는 그림끼리 선을 연결하세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./52/2.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz10/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz521, Quiz522 };
