import { Cookies } from "react-cookie";

const cookies = new Cookies();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (name: string, value: string, option?: any) =>
  cookies.set(name, value, { ...option });

export const getCookie = (name: string) => cookies.get(name);

export const deleteCookie = (name: string) => cookies.remove(name);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeCookie = (name: string, value: string, option?: any) =>
  cookies.set(name, value, { ...option });
