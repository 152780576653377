import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "700px",
};

function Quiz361() {
  return (
    <>
      <h2>점선을 따라 선을 그어보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="black"
            backgroundImage={require("./36/1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz7/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz362() {
  return (
    <>
      <h2>점선을 따라 선을 그어보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="black"
            backgroundImage={require("./36/2.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz7/3"
      >
        다음
      </Button>
    </>
  );
}

function Quiz381() {
  return (
    <>
      <h2>출발지에서 도착지까지 가는 길을 선으로 연결하세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./38/1.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz7/4"
      >
        다음
      </Button>
    </>
  );
}

function Quiz382() {
  return (
    <>
      <h2>출발지에서 도착지까지 가는 길을 선으로 연결하세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReactSketchCanvas
            style={styles}
            strokeWidth={4}
            strokeColor="red"
            backgroundImage={require("./38/2.png")}
          />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz8/1"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz361, Quiz362, Quiz381, Quiz382 };
