import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  styled,
  Dialog,
  Typography,
  Card,
  Grid,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { getCookie } from "../../support/Cookie";
import { Bar } from "react-chartjs-2";
import ReactToPrint from "react-to-print";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minWidth: "400px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AdminUserInfo(props: {
  user: { id: number; username: string; name: string };
}) {
  const [open, setOpen] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [username, setUsername] = useState();
  const [graphData, setGraphData] = useState([]);
  const [name, setName] = useState();
  const [authorityDtoSet, setAuthorityDtoSet] = useState([]);
  const [createdTime, setCreatedTime] = useState();

  const TOKEN = getCookie("token");
  const componentRef = useRef(null);

  useEffect(() => {
    getMyInfo();
    getResult();
    // eslint-disable-next-line
  }, []);

  const member = {
    id: props.user.id,
    username: props.user.username,
    name: props.user.name,
  };
  const handleClickOpen = () => {
    setOpen(true);
    console.log(member);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    makeArrayfromData(resultData);
  }, [resultData]);

  const makeArrayfromData = responseData => {
    for (const key in responseData) {
      const dataSuccess = [];
      const dataFailture = [];
      const dataDates = [];
      responseData[key].map(data => {
        const date = new Date(data.inspectionDate);
        const formatDate = `${date.getFullYear()}년 ${
          date.getMonth() + 1
        }월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
        dataSuccess.push({
          x: formatDate,
          y: data.success,
        });
        dataFailture.push({
          x: formatDate,
          y: data.fail,
        });
        dataDates.push(formatDate);
      });

      const dataForGraph = {
        labels: dataDates,
        datasets: [
          {
            label: "성공",
            data: dataSuccess,
            backgroundColor: "#B2A4FF",
            stack: "Stack 0",
          },
          {
            label: "실패",
            data: dataFailture,
            backgroundColor: "#FFB4B4",
            stack: "Stack 0",
          },
        ],
      };

      setGraphData(graphData => [
        ...graphData,
        { header: key, value: dataForGraph },
      ]);
    }
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const createGraph = data => {
    return (
      <Grid item xs={12} md={12} key={data.header}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h4">{data.header}</Typography>
            <Bar options={options} data={data.value} />
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const URL = process.env.REACT_APP_API_HOST;
  const getMyInfo = () => {
    axios
      .get(`${URL}/api/member/${member.username}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(response => {
        const profileData = response.data;
        setUsername(profileData.username);
        setName(profileData.name);
        setAuthorityDtoSet(profileData.authorityDtoSet);
        setCreatedTime(profileData.createdTime);
      })
      .catch(error => {
        alert(error);
      });
  };

  const getResult = () => {
    axios
      .get(`${URL}/api/${member.username}/inspect/results`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(response => {
        setResultData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  function ResultScreen(props) {
    const { printRef } = props;
    return (
      <DialogContent dividers ref={printRef}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <h3>이름 : {name}</h3>
            <h3>아이디 : {username}</h3>
            <h3>가입일시 : {createdTime}</h3>
            <h3>
              권한 :
              {authorityDtoSet.map(value => (
                <Chip key={value.authorityName} label={value.authorityName} />
              ))}
            </h3>
            <h2>검사 결과</h2>
            <Grid container spacing={2}>
              {graphData &&
                graphData.length > 0 &&
                graphData.map(value => createGraph(value))}
            </Grid>
          </>
        )}
      </DialogContent>
    );
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        상세보기
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <h3>{member.name}님 정보</h3>
          <ReactToPrint
            trigger={() => <Button>프린트하기</Button>}
            content={() => componentRef.current}
          />
        </BootstrapDialogTitle>
        <ResultScreen printRef={componentRef} />
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
