import { ListItemButton, ListItemText, List, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AdminSideBar() {
  const menus = [
    { name: "메인", path: "/admin" },
    { name: "전체 사용자", path: "/admin/user" },
    { name: "관리자 ", path: "/admin/addadmin" },
  ];

  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          {menus.map((menu, index) => (
            <ListItemButton
              onClick={() => navigate(menu.path)}
              key={index}
              sx={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText primary={menu.name} />
            </ListItemButton>
          ))}
        </List>
      </nav>
    </Box>
  );
}
