import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  CircularProgress,
  Grid,
  Button,
  CardContent,
  Card,
  Typography,
  Paper,
  Chip,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { useNavigate } from "react-router-dom";
import { getCookie } from "./Cookie";
import ReactToPrint from "react-to-print";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export default function MyInfo() {
  const [isProfileLoading, setProfileLoading] = useState(true);
  const [username, setUsername] = useState();
  const [name, setName] = useState();
  const [graphData, setGraphData] = useState([]);
  const [authorityDtoSet, setAuthorityDtoSet] = useState([]);
  const [resultData, setResultData] = useState([]);
  const TOKEN = getCookie("token");
  const ID = getCookie("username");

  const navigate = useNavigate();
  if (!(Number(getCookie("role")) > 0)) {
    navigate("/login");
  }

  const componentRef = useRef(null);

  useEffect(() => {
    setProfileLoading(true);
    const getData = async () => {
      await getMyInfo();
      await getResult();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    makeArrayfromData(resultData);
    setProfileLoading(false);
  }, [resultData]);

  const makeArrayfromData = responseData => {
    for (const key in responseData) {
      const dataSuccess = [];
      const dataFailture = [];
      const dataDates = [];
      responseData[key].map(data => {
        const date = new Date(data.inspectionDate);
        const formatDate = `${date.getFullYear()}년 ${
          date.getMonth() + 1
        }월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
        dataSuccess.push({
          x: formatDate,
          y: data.success,
        });
        dataFailture.push({
          x: formatDate,
          y: data.fail,
        });
        dataDates.push(formatDate);
      });

      const dataForGraph = {
        labels: dataDates,
        datasets: [
          {
            label: "성공",
            data: dataSuccess,
            backgroundColor: "#B2A4FF",
            stack: "Stack 0",
          },
          {
            label: "실패",
            data: dataFailture,
            backgroundColor: "#FFB4B4",
            stack: "Stack 0",
          },
        ],
      };

      setGraphData(graphData => [
        ...graphData,
        { header: key, value: dataForGraph },
      ]);
    }
  };

  const URL = process.env.REACT_APP_API_HOST;

  const getMyInfo = async () => {
    await axios
      .get(`${URL}/api/member/${ID}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(response => {
        const profileData = response.data;
        setUsername(profileData.username);
        setName(profileData.name);
        setAuthorityDtoSet(profileData.authorityDtoSet);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getResult = async () => {
    await axios
      .get(`${URL}/api/${ID}/inspect/results`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(response => setResultData(response.data))
      .catch(error => {
        console.log(error);
      });
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const createGraph = data => {
    return (
      <Grid item xs={12} md={6} key={data.header}>
        <Card elevation={0} variant="outlined">
          <CardContent>
            <Typography variant="h4">{data.header}</Typography>
            <Bar options={options} data={data.value} />
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <h1>내 정보</h1>
      {isProfileLoading ? (
        <CircularProgress />
      ) : (
        <>
          <ReactToPrint
            trigger={() => <Button>프린트하기</Button>}
            content={() => componentRef.current}
          />
          <Button
            size="large"
            onClick={() => {
              navigate("changepw");
            }}
          >
            비밀번호 변경
          </Button>
          <Paper ref={componentRef} elevation={0} sx={{ m: 2 }}>
            <h3>이름 : {name}</h3>
            <h3>아이디 : {username}</h3>
            <h3>
              권한 :
              {authorityDtoSet.map(value => (
                <Chip key={value.authorityName} label={value.authorityName} />
              ))}
            </h3>
            <h2>내 검사 결과</h2>
            <Grid container spacing={2}>
              {graphData &&
                graphData.length > 0 &&
                graphData.map(value => createGraph(value))}
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
