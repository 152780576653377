// QuizText.tsx 는 퀴즈 제목 및 설명을 위한 텍스트 및 TTS 를 위한 배열들 입니다.
// Quiz7, Quiz8은 여기 나온 텍스트와 보여지는 텍스트가 다른 부분이 있습니다
// 발음상으로 TTS가 읽기 편하도록 문장을 합친 부분이 있어서 해당 퀴즈들은 소스코드와 이 텍스트들을 비교하면서 수정해야합니다.

export const quizInfoText = [
  "",
  // 1
  "시간과 장소, 상황이나 환경 따위를 올바로 인식하는 능력을 길러주는 활동입니다.",
  // 2
  "",
  // 3
  "마음이나 주의를 집중할 수 있는 힘을 길러주는 활동입니다.",
  // 4
  "이전의 인상이나 경험을 의식 속에 간직해 두는 능력을 길러주는 활동입니다.",
  // 5
  "주어진 수나 식을 일정한 수학적 이론에 따라 처리하여 수치를 구하고 문제를 풀어내는 능력을 길러주는 활동입니다.",
  // 6
  "시각적 정보를 통하여 추론하는 능력을 길러주는 활동입니다.",
  // 7
  "공간 관계나 공간 위치를 감각을 통해 파악하는 능력을 길러주는 활동입니다.",
  // 8
  "문제 상황이 발생했을 경우 사고를 통해 이를 적절하게 해결하는 능력을 길러주는 활동입니다.",
  // 9
  "생각을 엮고 추론하는 능력을 길러주는 활동입니다.",
  // 10
  "실제로 행할수 있는 능력을 길러주는 활동입니다.",
];

export const quizTitleText = [
  [""],
  // Quiz 1
  ["계절 맞추기", "성별 및 연령대 구분하기"],
  // Quiz 2
  [""],
  // Quiz 3
  ["다른 모양 찾기", "빈칸 채워 넣기", "같은 위치에 표시하기"],
  // Quiz 4
  ["그림 기억하기", "단어 기억하기", "숫자 기억하기"],
  // Quiz 5
  ["더하기", "빼기", "숫자 계산하기", "더하기 빼기 계산하기"],
  // Quiz 6
  [
    // "다른 그림 찾기",
    "같은 방향 맞추기",
    "같은 모양과 색깔 찾기",
    "같은 그림 찾기",
    "배경 그림 찾기",
  ],
  // Quiz 7
  ["위치 인식하기", "과일 완성하기", "같은 도형 위치 찾기", "같은 모양 찾기"],
  // Quiz 8
  [
    "과일 이름 찾기",
    "물건 구입하기",
    "요일 및 날짜 맞추기",
    "상황에 맞는 물건 찾기",
    "속담 맞추기",
    "저울 비교",
  ],
  // Quiz 8
  ["도형 순서화하기", "논리적 질문하기", "수 배열 찾아내기"],
  // Quiz 10
  [
    "블록 개수 맞추기",
    "장소에 필요한 물건 인지하기",
    "일이 일어난 순서 맞추기",
    "동작 맞추기",
    "이야기 카드 순서 맞추기",
    "반대말 적기",
  ],
];

export const quizPageText = [
  [""],
  // 1
  [
    "",
    "여름에 해당하는 이미지를 찾아 클릭 또는 터치하세요",
    "다음 사진을 보고 성별이 다른 한 사람을 찾아 클릭 또는 터치하세요",
    "다음 사진에서 남성이 모두 몇 명인가요?",
    "다음 사진에서 여성이 모두 몇 명인가요?",
    "다음 사진에서 여성이 모두 몇 명인가요?",
  ],
  // 2
  [""],
  // 3
  [
    "",
    "다음 글자들 중 'ㅈ'과 같지 않는 글자를 찾아 클릭 또는 터치하세요.",
    "다음의 글자들 중 ‘ㄷ’자와 같지 않는 글자를 찾아 클릭 또는 터치하세요.",
    "다음의 글자들 중 ‘ㅎ’자와 같지 않는 글자를 찾아 클릭 또는 터치하세요.",
    "다음의 글자들 중 ‘ㅋ’자와 같지 않는 글자를 찾아 클릭 또는 터치하세요.",
    "빈칸에 들어갈 숫자를 모두 채워 넣으세요.",
    "빈칸에 들어갈 글자를 모두 채워 넣으세요.",
    "빈칸에 들어갈 숫자를 모두 채워 넣으세요.",
    "예시 그림 도형과 같게 아래 빈칸 위치에 클릭 또는 터치하세요.",
    "예시 그림 도형과 같게 아래 빈칸 위치에 클릭 또는 터치하세요.",
    "예시 그림 도형과 같게 아래 빈칸 위치에 클릭 또는 터치하세요.",
    "예시 그림 도형과 같게 아래 빈칸 위치에 클릭 또는 터치하세요.",
  ],
  // 4
  [
    "",
    "다음 그림을 잘 보고 기억하세요",
    "앞에서 봤던 그림을 찾아 클릭 또는 터치하세요",
    "다음 단어를 잘 보고 기억하세요",
    "앞에서 봤던 단어를 찾아 클릭 또는 터치하세요.",
    "다음 숫자를 잘 보고 기억하세요",
    "앞에서 봤던 숫자를 모두 찾아 클릭 또는 터치하세요.",
    "앞에서 봤던 숫자를 차례로 쓰세요.",
  ],
  // 5
  [
    "",
    "토끼가 말을 찾아 가는 길에서 총 몇 포기의 배추를 보았나요?",
    "토끼가 말을 찾아 가는 길에서 총 몇 개의 딸기를 보았나요?",
    "토끼가 말을 찾아 가는 길에서 총 몇 개의 당근를 보았나요?",
    "토끼가 말을 찾아 가는 길에서 총 몇 포기의 배추를 보았나요?",
    "누나가 사탕 5개를 가지고 있습니다. 동생에게 1개를 주면 누나는 모두 몇 개의 사탕을 가지고 있습니까?",
    "누나가 사탕 6개를 가지고 있습니다. 동생에게 3개를 주면 누나는 모두 몇 개의 사탕을 가지고 있습니까?",
    "누나가 사탕 11개를 가지고 있습니다. 동생에게 5개를 주면 누나는 모두 몇 개의 사탕을 가지고 있습니까?",
    "누나가 사탕 10개를 가지고 있습니다. 동생에게 6개를 주면 누나는 모두 몇 개의 사탕을 가지고 있습니까?",
    "다음의 숫자를 잘 보고, 수식에 맞도록 계산하세요",
    "다음 그림을 보고 계산하세요",
    "다음 그림을 보고 양과 피망을 각각 계산하세요",
    "다음 그림을 보고 소와 양배추를 각각 계산하세요",
  ],
  // Quiz6
  [
    "",
    "그림을 보고 같은 방향의 그림을 찾으세요",
    "앞에서 보았던 그림과 같은 방향의 그림을 찾아 클릭 또는 터치하세요",
    "다음 모양과 색깔이 모두 같은 도형을 찾아 클릭 또는 터치하세요.",
    "모양이 같은 도형을 찾아 클릭 또는 터치하세요.",
    "복합된 이미지의 그림 속에 어떤 그림들이 포함되어 있는지 찾아보세요",
  ],
  // Quiz 7 -> h2, h3 혼합해서 프론트로 보여주므로 TTS 글은 합쳤음
  [
    "",
    "지도를 잘 보고 질문에 답하세요. 서울특별시에서 가장 먼 지역은 어디인지 클릭 또는 터치하세요", // 해당 부분만 Quiz7 소스코드가서 수정해야함
    "과일의 이름과 빠진 부분을 아래에서 찾아 클릭 또는 터치하세요",
    "위의 도형과 같은 그림을 찾아 클릭 또는 터치하세요",
    "여러가지 도형을 보고 각각의 개수를 세어보세요",
  ],
  // Quiz 8
  [
    "",
    "다음 표를 보고 숨어있는 과일이름을 모두 찾아 클릭 또는 터치 하세요",
    "아래의 가격표를 보고 질문에 답 하세요. 돗보기 한 개와 메모장 한 개를 구입하려고 합니다. 비용은 얼마일까요?",
    "아래의 가격표를 보고 질문에 답 하세요. 새우볶음밥 한 그릇과 잡채밥 두 그릇을 먹었습니다. 얼마가 필요합니까?",
    "아래 질문을 읽고 올바른 날짜를 쓰세요",
    "아래 질문을 읽고 올바른 날짜와 요일을 쓰세요",
    "그림을 보고 다음의 질문에 답하시오, 양치를 하기 위해 필요한 것을 모두 찾아 클릭 또는 터치 하세요 (2가지)",
    "그림을 보고 다음의 질문에 답하시오, 배드민턴을 치기 위해 필요한 것을 모두 찾아 클릭 또는 터치 하세요 (2가지)",
    "다음의 속담구절에 알맞은 단어를 찾아 클릭 또는 터치하세요",
    "다음의 저울을 비교하여 가장 무거운 도형을 찾아 클릭 또는 터치하세요",
    "금주 수요일은 10월 22일 입니다. 금주 일요일은 며칠입니까?",
    "오늘은 1월 24일 목요일 입니다. 5일 후는 몇 월 며칠, 무슨 요일 입니까?",
    "오늘부터 7일이 지나면 새해 1월 1일 설날이 되고 월요일입니다. 오늘은 몇 월 며칠, 무슨 요일 입니까?",
    "1번, 낫 놓고 뭐뭐뭐도 모른다. 2번, 닭 쫒던 뭐 지붕 쳐다본다. 3번, 뭐뭐 밑이 어둡다. 4번, 뭐뭐뭐에 옷 젖는 줄 모른다. 5번, 가지 많은 나무에 뭐뭐 잘 날이 없다.",
    "1번, 뭐 빠진 독에 뭐 붓기. 2번, 뭐뭐 가는데 뭐 간다. 3번, 뭐는 뭐보다 진하다. 4번, 고생 끝에 뭐가 온다. 5번, 돌다리도 뭐뭐뭐 보고 건너라.",
  ],
  // quiz 9
  [
    "",
    "다음 도형의 구성 규칙에 따라 빈칸에 들어갈 적합한 도형을 찾아 클릭 또는 터치 하세요",
    "다음 도형의 구성 법칙에 따라 빈칸에 들어갈 적합한 도형을 찾아 클릭 또는 터치 하세요",
    "다음의 질문을 읽고 답해 주세요",
    "표 안의 숫자를 보고 질문에 답하세요",
    "ㄱ에 들어갈 숫자는 무엇입니까?, ㄴ에 들어갈 숫자는 무엇입니까? ㄱ과 ㄴ중 큰 수에서 작은 수를 뺀 값은 얼마입니까?",
    "ㄱ에 들어갈 숫자는 무엇입니까?, ㄴ에 들어갈 숫자는 무엇입니까? ㄱ에서 ㄴ을 뺀 후 ㄷ을 더한 값은 얼마입니까??",
  ],
  // quiz 10
  [
    "",
    "그림을 보고 총 몇 개의 블록이 사용되었는지 맞추어 보세요.",
    "화장실에서 필요 없는 물건을 찾아 클릭 또는 터치 하세요.",
    "침실에서 필요 없는 물건을 찾아 클릭 또는 터치 하세요.",
    "교실에서 필요 없는 물건을 찾아 클릭 또는 터치 하세요.",
    "옷장에서 필요 없는 물건을 찾아 클릭 또는 터치 하세요.",
    "제시된 동작을 먼저 일어난 순서대로 맞춰보세요",
    "동작1, 물마시기, 1. 물을 마신다. 2. 컵을 들어 올린다. 3. 컵에 물을 따른다.",
    "동작2, 전화걸기, 1. 다이얼을 누른다. 2. 수화기를 들어 올린다. 3. 전화기 앞으로 간다.",
    "동작1, 등산하기, 1. 산 정상에서 다 같이 사진을 찍었습니다. 2. 등산을 가기 위해 버스를 탑니다. 3. 사진을 찍은 후 모여서 점심을 먹었습니다. 4. 산에 오르기 시작합니다.",
    "동작2, 라면 끓이기, 1. 물을 끓인다. 2. 라면을 맛있게 먹는다. 3. 면과 스프를 넣는다. 4. 냄비에 물을 넣는다.",
    "다음 중 양말을 신는 그림을 찾아보세요",
    "다음 중 양치질을 하는 그림을 찾아보세요",
    "그림을 보고 윗옷을 입는 올바른 순서를 아래 빈칸에 쓰세요",
    "그림을 보고 머리감기 하는 올바른 순서를 아래 빈칸에 쓰세요",
    "이야기 카드를 잘 보고 일이 일어난 순서를 이야기해보세요",
    "아래 단어의 반대말을 적어주세요",
  ],
];

// quiz9  설명 추가
export const quizText9 = [
  "철수의 나이는 25세입니다. 10년 후에는 철수는 몇 세가 되나요?",
  "미희는 아들 1명과 딸 상둥이를 낳았습니다. 미희의 자녀는 총 몇 명인가요?",
  "세희의 나이는 14살입니다. 지금부터 5년 전에는 세희는 몇 살이었나요?",
  "솔이는 아빠, 엄마, 오빠 1명, 쌍둥이 여동생이 있습니다. 솔이의 가족은 총 몇 명인가요?",
  "과일 바구니 안에 사과 3개, 배 3개가 있었습니다. 과일 바구니 안에서 영희는 사과 2개를 먹었습니다. 철수는 배 1개를 먹었습니다. 과일 바구니 안에는 몇 개의 사과와 배가 남았습니까?",
];

// quiz10  설명 추가
export const quizText10 = [
  "당기다. 올라가다. 더럽다. 조용하다. 벗다. 높다. 두껍다. 남자",
  "배고프다. 덥다. 빠르다. 길다. 북쪽. 가다. 위쪽. 채우다",
];
