import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz101() {
  return (
    <>
      <h2>다음 동물이 내는 소리를 따라서 해보세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./100/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/14"
      >
        다음
      </Button>
    </>
  );
}

function Quiz102() {
  return (
    <>
      <h2>다음 동물이 내는 소리를 따라서 해보세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./100/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/15"
      >
        다음
      </Button>
    </>
  );
}

function Quiz103() {
  return (
    <>
      <h2>다음 그림의 명칭을 말하고 어울리는 노래를 불러주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./100/3.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/16"
      >
        다음
      </Button>
    </>
  );
}

function Quiz104() {
  return (
    <>
      <h2>다음 그림의 명칭을 말하고 어울리는 노래를 불러주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./100/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/17"
      >
        다음
      </Button>
    </>
  );
}

function Quiz105() {
  return (
    <>
      <h2>다음 그림의 명칭을 말하고 어울리는 노래를 불러주세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./100/5.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz2/18"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz101, Quiz102, Quiz103, Quiz104, Quiz105 };
