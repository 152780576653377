import React from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Button } from "@mui/material";

async function populateVoiceList(synth: SpeechSynthesis) {
  try {
    const voices = await synth.getVoices().sort(function (a, b) {
      const aname = a.name.toUpperCase();
      const bname = b.name.toUpperCase();
      if (aname < bname) {
        return -1;
      } else if (aname === bname) {
        return 0;
      } else {
        return +1;
      }
    });

    return voices;
  } catch (error) {
    throw new Error("Failure retrieving voices");
  }
}
// Voice Setting
const pitch = 1;
const rate = 0.7;
export async function speak(textToRead: string, synth: SpeechSynthesis) {
  if (speechSynthesis == undefined) {
    alert("음성 듣기가 지원하지 않는 인터넷 브라우저입니다.");
    return;
  }

  if (speechSynthesis.onvoiceschanged !== undefined) {
    speechSynthesis.onvoiceschanged = () => populateVoiceList;
  }

  if (synth.speaking) {
    console.error("speechSynthesis.speaking");
    return;
  }
  if (textToRead !== "") {
    const utterThis = new SpeechSynthesisUtterance(textToRead);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    utterThis.onend = function () {};
    utterThis.onerror = function () {
      console.error("SpeechSynthesisUtterance.onerror");
    };
    // utterThis.voice = voices[0]
    utterThis.pitch = pitch;
    utterThis.rate = rate;
    synth.speak(utterThis);
  }
}

export default function SpeakButton({ text }) {
  const cancleSpeech = () => {
    if (window.speechSynthesis != undefined) {
      window.speechSynthesis.cancel();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<CampaignIcon />}
        sx={{ mb: "13px" }}
        onClick={() => {
          cancleSpeech();
          speak(text, window.speechSynthesis);
        }}
      >
        설명 듣기
      </Button>
    </>
  );
}
