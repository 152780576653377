/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@mui/material";

function Quiz141() {
  return (
    <>
      <h2>다음 질문에 해당하는 장소들을 찾아 설명해주세요.</h2>
      <pre>
        ⇛ 공부하는 곳 ⇛ 물건을 파는 곳 ⇛ 손을 씻을 수 있는 곳 ⇛ 잠을 자는 곳
      </pre>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/4.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/5.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./41/6.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/10"
      >
        다음
      </Button>
    </>
  );
}

function Quiz142() {
  return (
    <>
      <h2>
        다음 그림을 보고 무엇을 하는 곳인지 설명해주세요. (그림 하나씩
        가르키면서 설명하기)
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/4.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/5.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./42/6.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/15"
      >
        다음
      </Button>
    </>
  );
}

function Quiz151() {
  return (
    <>
      <h2>다음 사진을 보고 성별이 다른 한 사람을 찾아보세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./51/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./51/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./51/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./51/4.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/12"
      >
        다음
      </Button>
    </>
  );
}

function Quiz152() {
  return (
    <>
      <h2>다음 사진을 보고 성별이 다른 사람끼리 연결하세요.</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/1.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/2.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/3.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/4.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/5.png")} />
        </Grid>
        <Grid item xs={6}>
          <img width="100%" src={require("./52/6.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/13"
      >
        다음
      </Button>
    </>
  );
}

function Quiz153() {
  return (
    <>
      <h2>다음 사진에서 남성이 모두 몇 명인가요?</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./53/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./53/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/14"
      >
        다음
      </Button>
    </>
  );
}

function Quiz154() {
  return (
    <>
      <h2>다음 사진에서 여성이 모두 몇 명인가요?</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./54/1.png")} />
        </Grid>
        <Grid item xs={12}>
          <img width="100%" src={require("./54/2.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz1/15"
      >
        다음
      </Button>
    </>
  );
}

export { Quiz141, Quiz142, Quiz151, Quiz152, Quiz153, Quiz154 };
