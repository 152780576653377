import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

function Quiz601() {
  return (
    <>
      <h2>제시된 사진을 보고 한 손으로 모양을 똑같이 따라 해보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./60/1.png")} />
        </Grid>
      </Grid>
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz10/2"
      >
        다음
      </Button>
    </>
  );
}

function Quiz602() {
  return (
    <>
      <h2>제시된 사진을 보고 한 손으로 모양을 똑같이 따라 해보세요</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img width="100%" src={require("./60/2.png")} />
        </Grid>
      </Grid>
      <Button component={Link} variant="contained" size="large" to="/">
        <h1>종료</h1>
      </Button>
    </>
  );
}

export { Quiz601, Quiz602 };
