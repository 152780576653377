import React from "react";
import { Link } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import { ReactSketchCanvas } from "react-sketch-canvas";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  height: "800px",
};

function Quiz141() {
  return (
    <Paper elevation={0}>
      <h2>
        다음의 숫자와 자음들을 숫자-자음 순으로 번갈아 연결하세요.
        <br />( 예 : ① → ㄱ → ② → ㄴ )
      </h2>
      <ReactSketchCanvas
        style={styles}
        strokeWidth={4}
        strokeColor="red"
        backgroundImage={require("./140/1.png")}
      />
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/6"
      >
        다음
      </Button>
    </Paper>
  );
}

function Quiz142() {
  return (
    <Paper elevation={0}>
      <h2>
        다음의 숫자를 큰 숫자에서 작은 숫자 순으로 연결하세요.
        <br />( 예 : ⑩ → ⑨ )
      </h2>
      <ReactSketchCanvas
        style={styles}
        strokeWidth={4}
        strokeColor="red"
        backgroundImage={require("./140/2.png")}
      />
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/7"
      >
        다음
      </Button>
    </Paper>
  );
}

function Quiz143() {
  return (
    <Paper elevation={0}>
      <h2>
        다음의 숫자와 자음을 자음-숫자 순으로 번갈아 연결하세요.
        <br />( 예 : ㄱ → ① → ㄴ→ ② )
      </h2>
      <ReactSketchCanvas
        style={styles}
        strokeWidth={4}
        strokeColor="red"
        backgroundImage={require("./140/3.png")}
      />
      <Button
        component={Link}
        variant="contained"
        size="large"
        to="/help/quiz3/8"
      >
        다음
      </Button>
    </Paper>
  );
}

export { Quiz141, Quiz142, Quiz143 };
