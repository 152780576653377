import React, { useEffect, useState } from "react";
import LoginCmp from "./components/support/Login";
import SignUp from "./components/support/SignUp";
import MyInfo from "./components/support/MyInfo";
import LostPage from "./components/support/LostPage";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Quiz1 from "./components/quizhelp/quiz1/Quiz1";
import Quiz2 from "./components/quizhelp/quiz2/Quiz2";
import Quiz3 from "./components/quizhelp/quiz3/Quiz3";
import Quiz4 from "./components/quizhelp/quiz4/Quiz4";
import Quiz5 from "./components/quizhelp/quiz5/Quiz5";
import Quiz6 from "./components/quizhelp/quiz6/Quiz6";
import Quiz7 from "./components/quizhelp/quiz7/Quiz7";
import Quiz8 from "./components/quizhelp/quiz8/Quiz8";
import Quiz9 from "./components/quizhelp/quiz9/Quiz9";
import Quiz10 from "./components/quizhelp/quiz10/Quiz10";
import Quiz from "./components/Quiz";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Container,
  Grid,
} from "@mui/material";

import {
  Login,
  AccountCircle,
  Logout,
  Settings,
  Home,
} from "@mui/icons-material";
import AdminMain from "./components/admin/Admin";
import {
  getCookie,
  deleteCookie,
  changeCookie,
} from "./components/support/Cookie";
import ChangePassword from "./components/support/ChangePW";

export default function App() {
  const [isLogin, setIsLogin] = useState(0);
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsLogin(Number(getCookie("role")));
  });
  const logout = () => {
    deleteCookie("token");
    changeCookie("role", "0");
    deleteCookie("username");
    navigate("/");
    console.log("logout");
    setIsLogin(0);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          두뇌활동 증진
          {isLogin > 0 ? (
            <>
              {" "}
              <IconButton
                size="large"
                edge="end"
                onClick={() => {
                  navigate("/quiz");
                }}
                color="inherit"
              >
                <Home />
              </IconButton>
            </>
          ) : (
            <>
              {" "}
              <IconButton
                size="large"
                edge="end"
                onClick={() => {
                  navigate("/");
                }}
                color="inherit"
              >
                {" "}
                <Home />
              </IconButton>
            </>
          )}
          {isLogin > 0 ? (
            <>
              <IconButton
                size="large"
                edge="end"
                onClick={logout}
                color="inherit"
              >
                <Logout />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                onClick={() => {
                  navigate("/myinfo");
                }}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </>
          ) : (
            <IconButton
              size="large"
              edge="end"
              onClick={() => {
                navigate("/login");
              }}
              color="inherit"
            >
              <Login />
            </IconButton>
          )}
          {isLogin == 2 ? (
            <IconButton
              size="large"
              edge="end"
              onClick={() => {
                navigate("/admin");
              }}
              color="inherit"
            >
              <Settings />
            </IconButton>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <br />
        <br />
        <Routes>
          <Route path="/" element={<Information />} />
          <Route path="/login" element={<LoginCmp />} />
          <Route path="/myinfo/changepw" element={<ChangePassword />} />
          <Route path="/*" element={<LostPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/quiz/*" element={<Quiz />} />
          <Route path="/myinfo" element={<MyInfo />} />
          <Route path="/help/quiz1/*" element={<Quiz1 />} />
          <Route path="/help/quiz2/*" element={<Quiz2 />} />
          <Route path="/help/quiz3/*" element={<Quiz3 />} />
          <Route path="/help/quiz4/*" element={<Quiz4 />} />
          <Route path="/help/quiz5/*" element={<Quiz5 />} />
          <Route path="/help/quiz6/*" element={<Quiz6 />} />
          <Route path="/help/quiz7/*" element={<Quiz7 />} />
          <Route path="/help/quiz8/*" element={<Quiz8 />} />
          <Route path="/help/quiz9/*" element={<Quiz9 />} />
          <Route path="/help/quiz10/*" element={<Quiz10 />} />
          <Route path="/admin/*" element={<AdminMain />} />
        </Routes>
      </Container>
    </>
  );
}

function Information() {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <h1 style={{ fontSize: "4.5vh", marginBlockEnd: "1px" }}>
          두뇌 커뮤니케이션
        </h1>
        <h2 style={{ fontSize: "3vh" }}>인지활동 프로그램</h2>
        <Button
          component={Link}
          style={{ fontSize: "2vh" }}
          size="large"
          variant="contained"
          to="/quiz"
        >
          퀴즈 시작하기
        </Button>
      </Grid>
    </>
  );
}
