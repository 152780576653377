import { useState } from "react";
import axios from "axios";
import { TextField, Button, Stack, Grid } from "@mui/material";
import { getCookie } from "../../support/Cookie";

export default function AdminAdd() {
  const [id, setID] = useState("");
  const [pw, setPW] = useState("");
  const [name, setName] = useState("");
  const [pwCheck, setPwCheck] = useState("");

  const TOKEN = getCookie("token");

  const signupBtn = () => {
    if (pwCheck === pw) {
      signUp();
    }
  };
  const signUp = () => {
    const URL = process.env.REACT_APP_API_HOST;
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
    axios
      .post(`${URL}/api/member/new/admin`, {
        name,
        username: id,
        password: pw,
      })
      .then(() => {
        alert("어드민이 추가되었습니다.");
        setID("");
        setPW("");
        setName("");
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Grid item xs={8}>
      <h1>관리자 아이디 생성</h1>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="이름을 입력하세요"
          variant="outlined"
          onChange={e => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="아이디를 입력하세요"
          variant="outlined"
          onChange={e => setID(e.target.value)}
        />
        <TextField
          fullWidth
          label="비밀번호를 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => setPW(e.target.value)}
        />
        <TextField
          fullWidth
          label="비밀번호를 다시 입력하세요"
          type="password"
          variant="outlined"
          onChange={e => setPwCheck(e.target.value)}
        />
        <Button variant="contained" fullWidth size="large" onClick={signupBtn}>
          관리자 아이디 생성
        </Button>
      </Stack>
    </Grid>
  );
}
